import { useState, FC, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import cx from 'classnames';

import { controlsAtom } from 'store';
import { ReactComponent as CountDownTimerIcon } from 'assets/icons/ic_timer.min.svg';
import { useInterval } from 'shared/hooks/useInterval';
import { useSound } from 'shared/hooks/useSound';
import { isMobile } from 'shared/helpers/isMobile';

import { IButtonProps, ITextProps } from './types';
import { StyledButton, StyledLabel, StyledText } from './style';

export const Label: FC<ITextProps> = ({ style, children }) => (
  <StyledLabel style={style}>{children}</StyledLabel>
);

export const Text: FC<ITextProps> = ({ style, children }) => (
  <StyledText style={style}>{children}</StyledText>
);

const INTERVAL_DELAY = 1000;

const Button: FC<IButtonProps> = ({
  children,
  style,
  disabled,
  mode = 'default',
  onClick,
  time = 0,
  startTime = 0,
  isInverseClickSound = false,
  onFinished,
}) => {
  const controls = useRecoilValue(controlsAtom);

  const [playBtnEnable] = useSound(!disabled ? 'btnEnable' : 'NOT_SOUND', {
    soundEnabled: isInverseClickSound ? !controls.sound : controls.sound,
  });
  const [playBtnHover] = useSound(!disabled ? 'btnHover' : 'NOT_SOUND');
  const [isMobileDevice] = useState<boolean>(isMobile());
  const [ms, setMs] = useState(-1);

  const count = useCallback(() => {
    const current = time + INTERVAL_DELAY - (dayjs().valueOf() - startTime);
    setMs(current > 0 ? current : 0);
  }, [time, startTime, setMs]);

  useInterval(count, time > 0 ? INTERVAL_DELAY : null);

  useEffect(() => {
    if (time > 0 && ms === 0 && onFinished) onFinished(0);
  }, [onFinished, time, ms]);

  useEffect(() => {
    count();
  }, [count]);

  const clickHandle = () => {
    playBtnEnable();
  };

  const hoverHandle = () => {
    if (!isMobileDevice) playBtnHover();
  };

  return (
    <StyledButton
      data-mode={mode}
      style={style}
      onMouseDown={clickHandle}
      onFocus={hoverHandle}
      onMouseEnter={hoverHandle}
      onClick={onClick}
      disabled={disabled}
      className={cx({
        'is-mobile': isMobileDevice,
      })}
    >
      {children}
      {mode === 'count-down' && ms >= 0 && (
        <>
          <span>{dayjs.duration(ms).format('HH:mm:ss')}</span>
          <CountDownTimerIcon />
        </>
      )}
    </StyledButton>
  );
};

export default Button;
