import { Key } from 'i18n/Key';
import img_info_cashout from 'assets/images/intro/info_cashout.min.png';
import img_info_mine from 'assets/images/intro/info_mine.min.png';
import img_info_start from 'assets/images/intro/info_start.min.png';
import img_info_bonus from 'assets/images/intro/info_bonus.min.png';
import { addDefaultTranslates } from 'i18n/config';

export const BONUS_SLIDE_INDEX = 3;

addDefaultTranslates({
  [Key.img_info_cashout]: img_info_cashout,
  [Key.img_info_mine]: img_info_mine,
  [Key.img_info_start]: img_info_start,
  [Key.img_info_bonus]: img_info_bonus,
});

export const slides = [
  {
    id: 2,
    img: Key.img_info_start,
    imgM: Key.img_info_start,
    header2: Key.infoP21,
    content: Key.infoP22,
  },
  {
    id: 3,
    img: Key.img_info_cashout,
    imgM: Key.img_info_cashout,
    header2: Key.infoP31,
    content: Key.infoP32,
  },
  {
    id: 4,
    img: Key.img_info_mine,
    imgM: Key.img_info_mine,
    header2: Key.infoP41,
    content: Key.infoP42,
  },
  {
    id: 5,
    img: Key.img_info_bonus,
    imgM: Key.img_info_bonus,
    header2: Key.infoP61,
    content: Key.infoP62,
  },
];
