import styled from 'styled-components';

export const SReactSpinePlayer = styled.div`
  position: absolute;
  background-size: contain;
  width: 360px;
  height: 240px;
  top: 0;
  z-index: 2;
  pointer-events: none;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    width: 310px;
    height: 205px;
    left: 50%;
    transform: translate(-50%, -1px);
  }

  @media (max-width: 600px) and (max-aspect-ratio: 1/1) {
    width: 260px;
    height: 180px;
    left: 50%;
    transform: translate(-50%, -4px);
  }

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    width: 210px;
    height: 140px;
    left: 50%;
    transform: translate(-50%, -3px);
  }

  @media (max-width: 325px) and (max-aspect-ratio: 1/1) {
    width: 180px;
    height: 160px;
    left: 50%;
    transform: translate(-50%, -3px);
  }
`;
