import styled, { css } from 'styled-components';
import iconArrow from 'assets/icons/ic_arrow_right.min.svg';

export const SWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background: var(--primary-color--06);
`;

export const SInner = styled.div`
  position: relative;
  max-width: 950px;
  width: 100%;
  height: 100%;
  max-height: 950px;
  border-radius: 8px;

  @media (max-width: 990px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const SBox = styled.div`
  position: relative;
  height: 100%;
  background: var(--primary-color);
  border-radius: 8px;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px 20px 15px;
  text-align: center;
  color: var(--inverse-color);
  display: flex;
  flex-direction: column;

  @media (max-width: 990px) {
    padding: 15px 0 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }
`;

export const SLogo = styled.div`
  img {
    max-width: none;
    height: 75px;
  }
`;

export const SSliderWrapper = styled.div`
  margin: 0 0 20px;
  position: relative;

  * {
    outline: none !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    left: 20px;
    bottom: 40px;
    z-index: 10;
    cursor: pointer;
    background: #2c2a1c;

    &:hover {
      background: #383622;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      line-height: 30px;
      font-size: 32px;
      color: rgb(255, 227, 16);
      transform: translate(-50%, -50%) rotate(180deg);
      background: url(${iconArrow}) center center no-repeat;
      background-size: auto 24px;
    }

    @media (max-width: 990px) {
      display: none;
    }
  }
  .swiper-button-next {
    right: 20px;
    left: auto;

    &:after {
      transform: translate(-50%, -50%);
    }
  }

  .swiper-pagination {
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 22;

    .swiper-pagination-bullet {
      position: relative;
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 1px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 6px;
        margin: 2px;
        border-radius: 3px;
        background: rgb(43, 41, 28);
        transition: width 0.05s;
        transform: translateX(-3px);
      }

      &.swiper-pagination-bullet-active {
        width: 20px;

        &:after {
          width: 16px;
          background: rgb(255, 227, 16);
        }
      }
    }
  }
`;

export const SItem = styled.section`
  width: 100%;
  padding: 0 0 40px;

  @media (max-width: 990px) {
    padding: 0 20px 20px;
  }
`;

export const SItemImg = styled.img`
  display: inline-block;
  max-width: 100%;
  max-height: 200px;
  height: auto;
`;

export const SText = styled.div<{ withMargin: boolean }>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 0 100px;
    `};

  @media (max-width: 990px) {
    margin: 0;
  }
`;

export const SItemHeader = styled.h4`
  margin: 10px 30px 0;
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 227, 16, 0.8);
`;

export const SItemHeader2 = styled.h3`
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
  color: rgb(255, 227, 16);
  text-transform: uppercase;

  @media (max-width: 990px) {
    margin: 10px 0;
  }
`;

export const SItemContent = styled.div`
  font-size: 14px;
  word-wrap: break-word;
  max-width: calc(100% - 30px);
  margin: 20px auto 10px;
  font-stretch: expanded;
  color: rgb(207, 208, 209);
`;

export const SFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  position: relative;
  z-index: 10;

  button {
    max-width: 230px;
    margin-bottom: 0 !important;
  }

  @media (min-width: 990px) {
    position: sticky;
    background: var(--primary-color);
    bottom: -40px;
    padding: 20px 10px;
  }

  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 990px) {
    margin-bottom: 90px;
  }
`;

export const SCheckedLabel = styled.label`
  position: absolute;
  display: flex;
  margin: 0;
  right: -10px;
  font-size: 15px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  justify-content: space-between;
  padding: 8px 25px 8px 14px;
  border-radius: 4px;

  strong {
    font-weight: 400;
    margin-right: 10px;
    opacity: 0.5;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  span {
    position: absolute;
    right: 10px;
    display: block;
    background: rgb(33, 34, 43);
    width: 18px;
    height: 18px;
    border-radius: 2px;
    overflow: hidden;
  }

  input:checked + span:before {
    content: '';
    display: block;
    position: absolute;
    height: 7px;
    width: 11px;
    border-left: 2px solid rgb(255, 227, 16);
    border-bottom: 2px solid rgb(255, 227, 16);
    transform: rotate(310deg) translate(0px, 4px);
  }

  @media (max-width: 990px) {
    position: relative;
    right: 0;
    margin-bottom: 30px;
    width: 100%;
    max-width: 270px;
    background: rgba(255, 227, 16, 0.1);
  }
`;

export const SBar = styled.div`
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const STabs = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 125px);
  position: relative;

  @media (max-width: 990px) {
    max-height: none;
  }
`;

export const STabHeader = styled.header`
  width: 100%;
  display: flex;
  background: var(--primary-color);
  z-index: 10;
  padding: 15px 10px;
  top: -20px;
  position: sticky;

  @media (max-width: 990px) {
    padding: 10px 10px;
    top: -15px;
  }
`;

export const STabHeaderInner = styled.div`
  display: flex;
  background: rgb(40, 234, 27, 0.05);
  margin: 0 auto;
  border-radius: 6px;
`;

export const STabButton = styled.button<{ isActive?: boolean }>`
  display: block;
  border: none;
  background: none;
  color: #fff;
  padding: 8px;
  min-width: 120px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  @media (max-width: 990px) {
    min-width: 0;
    padding: 8px 16px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: rgb(40, 234, 27);
      color: #13151d;

      &:hover {
        background: rgb(40, 234, 27);
      }
    `}
`;

export const STabContent = styled.div`
  overflow: hidden;
  overflow-y: auto;

  @media (max-width: 990px) {
    overflow: visible;
  }
`;
