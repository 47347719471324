import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { getLanguageFromUrl } from 'shared/helpers/parseUrl';
import { Key } from 'i18n/Key';

import en from 'i18n/translations/en.json';
import fr from 'i18n/translations/fr.json';
import sv from 'i18n/translations/sv.json';
import ru from 'i18n/translations/ru.json';
import tr from 'i18n/translations/tr.json';
import it from 'i18n/translations/it.json';
import hr from 'i18n/translations/hr.json';
import ro from 'i18n/translations/ro.json';

export const DEFAULT_NS = 'translation';
export const DEFAULT_LANG = 'en';

const resources = {
  en: {
    [DEFAULT_NS]: en,
  },
  sv: {
    [DEFAULT_NS]: sv,
  },
  fr: {
    [DEFAULT_NS]: fr,
  },
  ru: {
    [DEFAULT_NS]: ru,
  },
  tr: {
    [DEFAULT_NS]: tr,
  },
  it: {
    [DEFAULT_NS]: it,
  },
  hr: {
    [DEFAULT_NS]: hr,
  },
  ro: {
    [DEFAULT_NS]: ro,
  },
};

i18n.use(initReactI18next).init({
  resources: resources,
  lng: getLanguageFromUrl(),
  fallbackLng: DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
});

export const addDefaultTranslates = (
  trans: { [key in keyof typeof Key]?: string },
) => {
  const defaultTrans = i18n.getResourceBundle(DEFAULT_LANG, DEFAULT_NS);
  Object.entries(trans).forEach(([key, img]) => {
    if (!defaultTrans[img])
      i18n.addResources(DEFAULT_LANG, DEFAULT_NS, { [key]: img });
  });
};
