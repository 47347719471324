import styled from 'styled-components';

export const SWrapper = styled.div`
  position: relative;
  z-index: 100;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    padding-bottom: 0;
  }
`;

export const SPause = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
`;
