import { useState, useEffect, FC } from 'react';
import cx from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import { errorsAtom, processingAtom } from 'store';

import DynamiteAnim from 'shared/components/DynamiteAnim';
import { useSound } from 'shared/hooks/useSound';
import { isMobile } from 'shared/helpers/isMobile';

import { IBlockButtonProps } from './types';
import { SButton, SStone, SBox, SStrip, SBonusStrip } from './style';

const BlockButton: FC<IBlockButtonProps> = ({
  type,
  strip = 0,
  onClick,
  isSelected = false,
  isLast = false,
  disabled,
  isBonus = false,
  tile = '',
  extra,
}) => {
  const isProcessing = useRecoilValue(processingAtom);

  const [playStoneClick] = useSound('stoneClick', { volume: 0.2 });
  const [playStoneHover] = useSound('stoneHover', { volume: 0.2 });
  const [playGoldReveal] = useSound('goldReveal', { volume: 0.2 });
  const [playDynamiteExplosion] = useSound('dynamiteExplosion', {
    volume: 0.4,
  });
  const [errors, setErrors] = useRecoilState(errorsAtom);

  const [isMobileDevice] = useState<boolean>(isMobile());
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (isProcessing) return;

    playStoneClick();
    setIsClicked(true);
    if (onClick) onClick();
  };

  const hoverHandle = () => {
    if (!isMobileDevice) playStoneHover();
  };

  useEffect(() => {
    setIsClicked(false);

    if (errors.items.length === 0) {
      if (type === 'regular' && isLast && isSelected) playGoldReveal();
      if (type === 'loss' && isLast && isSelected) playDynamiteExplosion();
    } else {
      setErrors(prev => ({
        ...prev,
        items: prev.items.filter(item => item !== 'BET_ERROR'),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isLast, isSelected, errors.items, setErrors]);

  return (
    <SButton
      onMouseEnter={hoverHandle}
      onClick={handleClick}
      disabled={disabled || type !== 'block'}
      className={cx({
        'seaweed-shake-anim': isClicked,
        'is-last': isLast,
        'is-mobile': isMobileDevice,
        'is-gold': type === 'regular',
        'is-dynamite': type === 'loss',
        selected: isSelected,
      })}
      data-prize={extra?.prize || ''}
    >
      <SBox className={cx({ selectedGold: isSelected && type === 'regular' })}>
        {isBonus && <SBonusStrip />}
        {/*<SExplosion className={cx({ show: isSelected && type === 'loss' })} />*/}
        {isSelected && type === 'loss' && <DynamiteAnim />}
        {strip > 0 && (
          <SStrip>
            <span>&times;</span>
            <span>{strip}</span>
          </SStrip>
        )}
      </SBox>
      <SStone
        className={cx({
          opened: type !== 'block',
        })}
      >
        <img src={tile} alt="part" />
      </SStone>
    </SButton>
  );
};

export default BlockButton;
