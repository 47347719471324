export enum CharacterAnimations {
  Idle = 'idle',
  Lose = 'angry',
  Win = 'happy',
  Win2 = 'excited',
  Yawn = 'yawning',
}

export const CHARACTER_ANIMATION_JSON = '/animation/NathanYell.json';
export const CHARACTER_ANIMATION_ATLAS = '/animation/NathanYell.atlas';
