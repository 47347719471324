import { atom } from 'recoil';
import { TTabs } from 'shared/components/Intro/types';

type TIntro = null | {
  tab?: TTabs;
  sliderIndex?: number;
  scrollTo?: string;
};

export const introState = atom<TIntro>({
  key: 'intro',
  default: null,
});
