import styled, { css } from 'styled-components';
import srcBonusSymbolActive from 'assets/icons/bonus_active.min.png';
import srcBonusSymbol from 'assets/icons/bonus_inactive.min.png';

const common = css`
  background: var(--btn-bg-active);
`;

export const SWrapper = styled.div<{ disabled: boolean }>`
  margin-bottom: 6px;
  border-radius: 4px;
  touch-action: none;

  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(1);
    `}
`;

export const SInner = styled.div`
  display: flex;
  height: 70px;
`;

export const SLeft = styled.div`
  flex: 0 0 50px;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  ${common}
`;

export const SRight = styled.div`
  flex: 0 0 50px;
  border-radius: 0 4px 4px 0;
  overflow: hidden;
  ${common}
`;

export const SCenter = styled.div`
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
  position: relative;
  border: 1px solid rgb(19, 21, 29);
  border-top: none;
  border-bottom: none;
  ${common}
`;

export const SCenterOver = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
`;

export const StyleLabel = styled.span`
  display: block;
  width: 100%;
  border: none;
  color: var(--btn-c-label);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

export const StyleValue = styled.span`
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  height: 22px;
  border: none;
  color: var(--btn-c-text);
`;

export const SCenterCover = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`;

export const SButton = styled.button`
  background: none;
  width: 100%;
  height: 50%;
  border: none;
  font-weight: 400;
  text-align: center;
  padding: 0;
  font-size: 15px;
  line-height: 15px;
  color: var(--btn-c-highlight);
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s;

  &:disabled {
    cursor: default;
  }

  @media (min-width: 990px) and (min-aspect-ratio: 1/1) {
    &:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  svg {
    transform: translate(0, 3px);
    color: var(--btn-c-text);
  }
`;

export const SProgress = styled.span`
  display: block;
  position: absolute;
  min-width: 10px;
  background: var(--btn-c-highlight);
  height: 4px;
  bottom: 0;
  pointer-events: none;
  width: 50%;
`;

export const SInput = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 10px;
  width: calc(100% - 20px);
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const SBonusStrip = styled.span<{ disabled: boolean }>`
  width: 40px;
  height: 40px;
  z-index: 100;
  right: 2px;
  top: 16px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
  background-image: url('${srcBonusSymbolActive}');
  pointer-events: none;

  ${({ disabled }) =>
    disabled &&
    css`
      background-image: url('${srcBonusSymbol}');
    `}
`;
