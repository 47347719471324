import styled, { css } from 'styled-components';
import iconArrow from 'assets/icons/ic_arrow_right.min.svg';

export const SWrapper = styled.div`
  position: relative;
  margin: 20px 0;
`;

export const SScrollElement = styled.span`
  display: block;
  position: absolute;
  top: -60px;
`;

export const SHeader = styled.button<{ isActive: boolean }>`
  border: none;
  display: block;
  width: 100%;
  position: relative;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  background: var(--secondary-color);
  cursor: pointer;
  transition: background 0.4s;

  &:after {
    display: block;
    position: absolute;
    right: 8px;
    top: 9px;
    content: '';
    width: 16px;
    height: 16px;
    background: url(${iconArrow}) center center no-repeat;
    background-size: 100% auto;
    transform: rotate(90deg);
    transition: transform 0.4s;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: rgba(40, 234, 27, 0.1);
      color: rgb(40, 234, 27);

      &:after {
        transform: rotate(-90deg);
      }
    `}
`;

export const SBody = styled.div``;
