import { useState, useMemo, ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import threepiClient from 'api/threepiClient';

import { introState } from 'store';
import imgGameLogo from 'assets/images/game_logo.min.png';
import Button from 'shared/components/Button';
import { Key } from 'i18n/Key';

import { Slider, About } from './Tabs/';

import {
  SBox,
  SLogo,
  SFooter,
  SCheckedLabel,
  STabs,
  STabHeader,
  STabHeaderInner,
  STabButton,
  STabContent,
} from './style';
import { TTabs } from './types';

const Intro: FC<{
  setIsIntro: (showIntro: boolean, isFlag: boolean) => void;
  showFlag?: boolean;
}> = ({ setIsIntro, showFlag }) => {
  const introStatus = useRecoilValue(introState);
  const [currentTab, setCurrentTab] = useState<TTabs>(
    introStatus?.tab ? introStatus?.tab : 'tutorial',
  );
  const [scrollTo, setScrollTo] = useState<string | null>(
    introStatus?.scrollTo || null,
  );

  const { t } = useTranslation();
  const [flag, setFlag] = useState<boolean>(false);

  const handleFlag = () => {
    setFlag(prev => !prev);
  };

  const handleOnClick = () => {
    threepiClient.updateGameHelp(false);

    setIsIntro(false, flag);
  };

  const handleTab = (tab: TTabs) => {
    setCurrentTab(tab);
    setScrollTo(null);
  };

  const tabs: {
    [key in TTabs]: { label: string; render: () => ReactElement };
  } = useMemo(
    () => ({
      tutorial: {
        label: t(Key.tutorial),
        render: () => <Slider showFlag={showFlag} />,
      },
      about: {
        label: t(Key.about),
        render: () => <About scrollTo={scrollTo} />,
      },
    }),
    [showFlag, t, scrollTo],
  );

  return (
    <SBox data-box="intro">
      <SLogo>
        <img src={imgGameLogo} alt="Christmas Miner" />
      </SLogo>

      <STabs>
        <STabHeader>
          <STabHeaderInner>
            {Object.keys(tabs).map(tabKey => (
              <STabButton
                isActive={tabKey === currentTab}
                key={tabKey}
                onClick={() => handleTab(tabKey as TTabs)}
              >
                {tabs[tabKey as TTabs].label}
              </STabButton>
            ))}
          </STabHeaderInner>
        </STabHeader>

        {Object.keys(tabs).map(
          tabKey =>
            tabKey === currentTab && (
              <STabContent key={tabKey}>
                {tabs[tabKey as TTabs].render()}
              </STabContent>
            ),
        )}
      </STabs>

      <SFooter>
        {showFlag && (
          <SCheckedLabel>
            <strong>{t(Key.doNotShow)}</strong>
            <input type="checkbox" onChange={handleFlag} />
            <span />
          </SCheckedLabel>
        )}

        <Button mode="warning" onClick={handleOnClick}>
          {t(Key.continue)}
        </Button>
      </SFooter>
    </SBox>
  );
};

export default Intro;
