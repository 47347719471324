export const createTranslation = (
  literal: string,
  t: (literal: string) => string,
  country?: string,
) => {
  if (!country) {
    return t(literal);
  }

  const nl = `${literal}-${country}`;
  const cl = t(nl);

  return cl === nl ? t(literal) : cl;
};
