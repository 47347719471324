import {
  useState,
  useEffect,
  useRef,
  useCallback,
  FC,
  TouchEvent,
} from 'react';
import { useRecoilValue } from 'recoil';
import { processingAtom } from 'store';

import { useSound } from 'shared/hooks/useSound';
import Span from 'shared/components/Span';

import { ReactComponent as PlusIcon } from 'assets/icons/ic_plus.min.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/ic_minus.min.svg';

import { IControllerProps } from './types';
import {
  SWrapper,
  SInner,
  SCenter,
  SCenterCover,
  SCenterOver,
  SLeft,
  SRight,
  SButton,
  StyleLabel,
  StyleValue,
  SProgress,
  SInput,
  SBonusStrip,
} from './style';
import gameBridgeManager, { receiveMessageTypes } from 'api/gameBridge';

const Controller: FC<IControllerProps> = ({
  label,
  name,
  options,
  value: prevValue,
  max,
  unit = '',
  disabled = false,
  haveBonus = false,
  showBonus = false,
  hasMaxButton = true,
  hasMinButton = true,
  onChange,
}) => {
  const isProcessing = useRecoilValue(processingAtom);

  const [playBtnEnable] = useSound('btnEnable');
  const [playBtnHover] = useSound('btnHover');

  const inputRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>(0);
  const [valueIndex, setValueIndex] = useState<number>(() =>
    options.reduce((acc, opt, index) => (opt === prevValue ? index : acc), 0),
  );

  useEffect(() => {
    maxBetCallRef.current = () => {
      if (disabled) {
        return;
      }

      setValidatedValueIndex(options.length - 1);
    };
  }, [disabled, options]);

  const maxBetCallRef = useRef(() => {});

  useEffect(() => {
    if (name !== 'amount') {
      return;
    }

    const gameBridgeListener = async (message: any) => {
      switch (message.event ? message.event.toLowerCase() : undefined) {
        case receiveMessageTypes.RMT_SET_MAX_BET.toLowerCase():
          maxBetCallRef.current();
          break;
      }
    };

    gameBridgeManager.subscribe(gameBridgeListener);

    return () => {
      gameBridgeManager.unsubscribe(gameBridgeListener);
    };
  }, [name]);

  const setValidatedValueIndex = useCallback(
    (index: number) => {
      let maxIndex = -1;

      if (max && max > 0) {
        maxIndex = options.reduce((acc: number, val: number, ind: number) => {
          if (val <= max) {
            acc = ind;
          }
          return acc;
        }, 0);
      }

      if (maxIndex === -1) {
        return setValueIndex(index);
      }

      setValueIndex(index <= maxIndex ? index : maxIndex);
    },
    [setValueIndex, max, options],
  );

  const handleChangeSlider = () => {
    setValidatedValueIndex(parseInt(inputRef.current?.value || '0', 10));
  };

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      if (!inputRef.current || inputRef.current?.disabled) return;

      const { pageX } = event.touches[0];
      const { left, width } = inputRef.current.getBoundingClientRect();

      if (left + width <= pageX + 10) {
        setValidatedValueIndex(options.length - 1);
        return;
      } else if (left > pageX) {
        setValidatedValueIndex(0);
        return;
      } else {
        setValidatedValueIndex(
          parseInt(((options.length * (pageX - left)) / width).toString(), 10),
        );
      }
    },
    [options.length, setValidatedValueIndex],
  );

  const changeBy = (mode: number) => {
    setValidatedValueIndex(
      options[valueIndex + mode] ? valueIndex + mode : valueIndex,
    );
  };

  const handleClick = (mode: 'min' | 'max' | -1 | 1) => () => {
    switch (mode) {
      case 'min':
        setValidatedValueIndex(0);
        break;
      case 'max':
        setValidatedValueIndex(options.length - 1);
        break;
      case -1:
        changeBy(-1);
        break;
      case 1:
        changeBy(1);
        break;
      default:
    }
  };

  const clickHandle = () => {
    playBtnEnable();
  };

  const hoverHandle = () => {
    playBtnHover();
  };

  useEffect(() => {
    setProgress(((valueIndex + 1) / options.length) * 100);

    onChange(name, options[valueIndex]);
  }, [valueIndex, onChange, options, name]);

  return (
    <SWrapper disabled={disabled}>
      <SInner>
        <SLeft>
          <SButton
            onMouseDown={clickHandle}
            onMouseEnter={hoverHandle}
            disabled={disabled || isProcessing}
            onClick={handleClick(-1)}
          >
            <MinusIcon />
          </SButton>
          {hasMinButton && (
            <SButton
              onMouseDown={clickHandle}
              onMouseEnter={hoverHandle}
              disabled={disabled || isProcessing}
              onClick={handleClick('min')}
            >
              min
            </SButton>
          )}
        </SLeft>
        <SCenter>
          {showBonus && <SBonusStrip disabled={!haveBonus} />}
          <SCenterOver>
            <StyleLabel>
              <span>{label}</span>
            </StyleLabel>
            <StyleValue>
              <Span hexCode={unit} />
              <span>{options[valueIndex]}</span>
            </StyleValue>
          </SCenterOver>
          <SCenterCover>
            <SInput
              onMouseDown={clickHandle}
              onMouseEnter={hoverHandle}
              disabled={true}
              //disabled={disabled || isProcessing}
              ref={inputRef}
              type="range"
              min="0"
              max={options.length - 1}
              step={1}
              value={valueIndex}
              onTouchStart={handleTouchMove}
              onTouchMove={handleTouchMove}
              onChange={handleChangeSlider}
            />
            <SProgress
              style={{
                width: `${progress}%`,
              }}
            />
          </SCenterCover>
        </SCenter>
        <SRight>
          <SButton
            onMouseDown={clickHandle}
            onMouseEnter={hoverHandle}
            disabled={disabled || isProcessing}
            onClick={handleClick(1)}
          >
            <PlusIcon />
          </SButton>
          {hasMaxButton && (
            <SButton
              onMouseDown={clickHandle}
              onMouseEnter={hoverHandle}
              disabled={disabled || isProcessing}
              onClick={handleClick('max')}
            >
              max
            </SButton>
          )}
        </SRight>
      </SInner>
    </SWrapper>
  );
};

export default Controller;
