import { FC, default as React } from 'react';

import { SHeader, SRoof } from './style';
import ReactSpinePlayer from '../SpinePlayer/ReactSpinePlayer';

const Header: FC = ({ children }) => {
  return (
    <SHeader>
      <SRoof />
      <ReactSpinePlayer />
      {children}
    </SHeader>
  );
};

export default Header;
