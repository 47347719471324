import styled, { css } from 'styled-components';
import boardSrc from 'assets/images/board.min.png';

export const SWrapper = styled.div<{ isPlaying: boolean }>`
  position: relative;
  padding: 65px 20px 30px;
  background-image: url('${boardSrc}');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  user-select: none;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    padding: 60px 20px 50px;
  }

  @media (max-width: 600px) and (max-aspect-ratio: 1/1) {
    padding: 55px 20px 40px;
  }

  @media (max-width: 520px) and (max-aspect-ratio: 1/1) {
    padding: 40px 15px 35px;
  }

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    padding: 30px 10px 30px;
  }

  @media (max-width: 400px) and (max-aspect-ratio: 1/1) {
    padding: 30px 10px 20px;
  }

  @media (max-width: 320px) and (max-aspect-ratio: 1/1) {
    padding: 30px 10px 15px;
  }
`;

export const SInner = styled.div<{
  disabled: boolean;
  cellAnimDelay: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${({ cellAnimDelay }) => cellAnimDelay}
`;
