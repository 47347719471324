import styled, { css, keyframes } from 'styled-components';
import coinSrc from 'assets/images/prize_1.min.png';
import mineSrc from 'assets/images/mine.min.png';
import bonusSrc from 'assets/icons/bonus_label.min.png';

const fixedStyle = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const SWrapper = styled.div`
  ${fixedStyle};
  z-index: 300;
  vertical-align: top;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow-y: auto;
  user-select: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 100%;
    width: 0;
    margin-left: -5px;
    pointer-events: none;
  }
`;

export const SOverlay = styled.div`
  ${fixedStyle};
  z-index: 10;
`;

export const SFooter = styled.footer`
  margin-top: 10px;
  text-align: center;
  padding: 10px 0;
  position: sticky;
  bottom: 0;

  button[data-mode='warning'] {
    margin-bottom: 0;
  }

  @media (max-width: 540px) {
    margin-top: 10px;
  }
`;

export const SModal = styled.div`
  position: relative;
  vertical-align: top;
  display: inline-block;
  max-width: 440px;
  padding: 20px 0;
  width: calc(100% - 40px);
  white-space: normal;
  z-index: 20;

  @media (max-width: 540px) {
    padding: 5px 0;
    width: calc(100% - 10px);
  }
`;

export const SInner = styled.div`
  background: var(--primary-color);
  padding: 12px 12px 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5);
  text-align: left;
  line-height: 24px;

  @media (max-width: 540px) {
    padding: 10px;
  }
`;

export const STitle = styled.h2`
  margin: 10px 0 20px;
  font-weight: 600;
  text-align: center;
`;

export const SSubTitle = styled.h3`
  margin: 10px 0 20px;
  font-weight: 400;
  text-align: center;
`;

const animRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const animDash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
`;

export const SSpinner = styled.div`
  text-align: center;

  svg {
    width: 2em;
    transform-origin: center;
    animation: ${animRotate} 2s linear infinite;
  }

  circle {
    fill: none;
    stroke: var(--inverse-color);
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${animDash} 1.5s ease-in-out infinite;
  }
`;

export const SNoItems = styled.div`
  text-align: center;
  height: 50px;
  line-height: 50px;
  opacity: 0.6;
`;

export const SList = styled.div`
  line-height: 18px;
  min-height: 50px;
`;

export const SItem = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  text-align: left;
  font-size: 18px;
  margin: 0 0 15px;
  gap: 1px;
  color: var(--iron-color);
  background: var(--secondary-color);
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transition: background 0.25s;

  &[data-is-open='false']:hover {
    background: var(--secondary-color--hover);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

export const SSections = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SSection = styled.div`
  display: block;
  flex: 0 0 calc(33.3333% - 1px);

  @media (max-width: 540px) {
    &:last-child {
      flex: 0 0 calc(100% - 1px);
    }

    flex: 0 0 calc(50% - 1px);
  }
`;

export const SField = styled.span`
  display: block;
  padding: 6px 8px;
`;

export const SFieldValue = styled.span`
  display: block;
  font-weight: 500;
  margin: 0 0 5px;
`;

export const SFieldKey = styled.span`
  white-space: nowrap;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  min-height: 20px;
  opacity: 0.5;
`;

export const SMore = styled.span`
  display: block;
  font-size: 14px;
  text-align: center;
  opacity: 0.8;
  color: var()
  margin-bottom: 5px;
`;

export const SCollapser = styled.div`
  flex: 0 0 calc(100% - 2px);

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    display: flex;
  }

  span {
    background-color: var(--primary-color);
    color: var(--inverse-color);
    border-radius: 50%;
    padding: 2px 6px;
    display: none;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    top: 3px;
    left: 3px;
  }

  li {
    position: relative;
    display: block;
    flex: 0 0 20%;
    width: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 92% auto;
    background-position: 50% 50%;
    opacity: 0.3;

    &[data-is-bonus='true']:after {
      position: absolute;
      display: block;
      content: '';
      background-size: 100% auto;
      width: 32px;
      height: 40px;
      z-index: 100;
      right: 3px;
      top: 3px;
      background: none no-repeat 50% 50%;
      background-size: auto 100%;
      pointer-events: none;
      background-image: url(${bonusSrc});
      @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
        width: 30px;
        height: 30px;
      }
    }

    &:before {
      position: relative;
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    &[data-cell-type='regular'] {
      background-image: url(${coinSrc});
    }

    &[data-cell-type='loss'] {
      background-image: url(${mineSrc});
    }

    &[data-is-open='true'] {
      opacity: 1;

      span {
        display: block;
      }
    }
  }
`;
