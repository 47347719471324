import gameBridgeManager from 'api/gameBridge';
import { exitGame } from 'api/relaxClient';
import threepiClient from 'api/threepiClient';

export const exitLobby = (data: any): boolean => {
  if (data?.customer === 'onextwo') {
    threepiClient.exitGame();

    if (!threepiClient.clientAPI.doLobbyURLNavigate) {
      return true;
    }
  }

  if (data?.customer === 'relax') {
    exitGame();
    return true;
  }

  gameBridgeManager.exit();

  try {
    if (data?.lobby_url || data?.lobbyurl) {
      if (data?.customer === 'betconstruct') {
        let lobby_url = data?.lobby_url || data?.lobbyurl || '';
        const [start, end] = decodeURIComponent(lobby_url).split('?');
        lobby_url = start + '?' + encodeURIComponent(end);
        data.lobby_url = data.lobbyurl = lobby_url;
      }

      window?.open(data?.lobby_url || '', '_top');
      return true;
    }
  } catch (e) {}

  return false;
};

export const openHistoryUrl = (url: string) => {
  try {
    if (url) {
      window?.open(url, '_blank', 'noopener,noreferrer');
    }
  } catch (e) {}
};
