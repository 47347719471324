import { FC, ReactElement, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { Key } from 'i18n/Key';
import PureTable from 'shared/components/PureTable';
import Collapser from 'shared/components/Collapser';
import { betsListState } from 'shared/components/BetsList';

import { gameInfoAtom } from 'store';
import { createTranslation } from 'shared/helpers/translationUtils';

import { elements, aboutRTP } from './aboutElements';
import {
  SWrapper,
  SContent,
  SHeader,
  SSubHeader,
  STypeText,
  STypeHeader,
  STypeTitle,
  STypeFigure,
  STypeCaption,
  STypeImage,
  STypeList,
  STypeItem,
  SButton,
} from './style';
import { TElement, TImageElement, TListElement, TTableElement } from './types';

export const About: FC<{
  scrollTo?: string | null;
}> = ({ scrollTo }) => {
  const { t } = useTranslation();
  const setBetsList = useSetRecoilState(betsListState);
  const gameInfo = useRecoilValue(gameInfoAtom);

  const els = useMemo(
    () => elements.map((el, index) => ({ ...el, id: index + 1 })),
    [],
  );

  const render = ({ type, content }: TElement, id: number): ReactElement => {
    const { title, src, caption } = content as TImageElement;

    switch (type) {
      case 'header':
        return (
          <STypeHeader>
            {createTranslation(content as string, t, gameInfo?.country)}
          </STypeHeader>
        );
      case 'text':
        return (
          <STypeText>
            {createTranslation(content as string, t, gameInfo?.country)}
          </STypeText>
        );
      case 'image':
        return (
          <>
            <STypeTitle>
              {createTranslation(title, t, gameInfo?.country)}
            </STypeTitle>
            {createTranslation(src, t, gameInfo?.country) && (
              <STypeFigure>
                <STypeImage
                  src={createTranslation(src, t, gameInfo?.country)}
                  alt={createTranslation(title, t, gameInfo?.country)}
                />
              </STypeFigure>
            )}
            <STypeCaption>
              {createTranslation(caption, t, gameInfo?.country)}
            </STypeCaption>
          </>
        );
      case 'list':
        return (
          <STypeList>
            {(content as TListElement[]).map(
              ({ title: listTitle, text: listText }) => (
                <STypeItem key={listTitle}>
                  <strong>
                    {createTranslation(listTitle, t, gameInfo?.country)}
                  </strong>
                  <br />
                  <span>
                    {createTranslation(listText, t, gameInfo?.country)}
                  </span>
                </STypeItem>
              ),
            )}
          </STypeList>
        );
      case 'table':
        const {
          title: tTitle,
          title2: tTitle2,
          columns: tColumns,
          data: tData,
        } = content as TTableElement;
        return (
          <Collapser id={id} title={`${t(tTitle)}${tTitle2}`}>
            <PureTable
              columns={tColumns.map(col => t(col))}
              data={tData.map((item, index) => [index + 1, item])}
            />
          </Collapser>
        );
    }
  };

  useEffect(() => {
    if (scrollTo) {
      const el = document.querySelector(`[${scrollTo}]`);

      if (el)
        el.scrollIntoView({
          behavior: 'smooth',
        });
    }
  }, [scrollTo]);

  const handleBetsList = () => {
    setBetsList({
      isActive: true,
      isLoading: true,
    });
  };

  return (
    <SWrapper>
      <SHeader>
        {createTranslation(Key.aboutTitle, t, gameInfo?.country)}
      </SHeader>
      <SSubHeader>
        {createTranslation(aboutRTP, t, gameInfo?.country)}
      </SSubHeader>

      {els.map(({ id, ...rest }) => (
        <SContent key={id}>{render(rest, id)}</SContent>
      ))}

      <SButton onClick={handleBetsList}>{t(Key.betsHistory)}</SButton>
    </SWrapper>
  );
};

export default About;
