import styled from 'styled-components';

export const SWrapper = styled.b`
  position: absolute;
  display: block;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: none;

  img {
    top: 0px;
    left: 0px;
    position: absolute;
    height: 150px;
    width: 1200px;
    animation: DynamiteAnim 1s steps(8) forwards;
  }

  @media (max-width: 440px) and (max-aspect-ratio: 1/1) {
    width: 100px;
    height: 100px;

    img {
      height: 100px;
      width: 800px;
      animation: DynamiteAnimMobile 1s steps(8) forwards;
    }
  }
`;
