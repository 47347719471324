import styled, { css } from 'styled-components';

export const SToasts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 15px;
  top: 10px;
  margin-top: -40px;
  height: 1px;
`;

export const SToast = styled.span<{ type: string }>`
  display: block;
  height: 22px;
  font-size: 17px;
  font-weight: 500;
  padding: 1px 10px;
  border-radius: 11px;
  color: var(--toast-c-cashout);
  background: var(--toast-bg-cashout);
  margin-bottom: 8px;
  min-width: 40px;

  &.toastAnim {
    animation: fadeInAnim 2s forwards;
  }

  ${({ type }) =>
    type === 'start' &&
    css`
      color: var(--toast-c-bet);
      background: var(--toast-bg-bet);
    `};

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    font-size: 14px;
    line-height: 22px;
  }
`;
