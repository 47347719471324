import styled from 'styled-components';

export const SPreloader = styled.div`
  display: flex;
  background: #323031;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200%;
  z-index: 1000;
`;

export const SLogo = styled.div`
  position: fixed;
  top: 50%;
  flex: 0 0 100%;
  width: 100%;
  max-width: 400px;
  height: 280px;
  animation: fadeIn 0.5s forwards;
  transform: translateY(-50%);
`;

export const SImage = styled.div`
  max-width: 400px;
  width: 100%;
  height: 282px;
`;

export const SProgressBar = styled.div`
  position: relative;
  width: 40%;
  height: 5px;
  margin: -30px auto 0;
  background: rgba(35, 34, 34);
  border-radius: 4px;
  overflow: hidden;
`;

export const SProgressTrack = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: #aa19ab;
  background-image: linear-gradient(90deg, #aa19ab, #e00e52);
  border-radius: 4px;
  height: 100%;
`;
