import { FC } from 'react';
import { SWrapper, SText } from './style';

const PureTable: FC<{
  isFirstKey?: boolean;
  stickyHeaderTop?: number;
  columns: string[];
  data: (string | number)[][];
}> = ({ isFirstKey = false, stickyHeaderTop = 35, columns, data = [] }) => {
  return (
    <SWrapper>
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th style={{ top: stickyHeaderTop }} key={column}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={row[0]}>
              {row.map((cell, index) =>
                isFirstKey && index === 0 ? null : (
                  <td key={cell}>
                    <SText>{cell}</SText>
                  </td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </SWrapper>
  );
};

export default PureTable;
