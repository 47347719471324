import { FC, useMemo, useEffect, useState } from 'react';

import BlockButton from 'shared/components/BlockButton';
import InfoBoard from 'shared/components/InfoBoard';

import { getRandom } from 'shared/helpers/random';

import { tilesArray } from './constants';
import { SWrapper, SInner } from './style';
import { IBoardProps, ICell } from './types';

const initialCell: ICell = {
  id: 0,
  name: 'Rock',
  type: 'block',
};

const initialRows = Array(5)
  .fill(1)
  .map(() =>
    Array(5)
      .fill(1)
      .map(() => ({ ...initialCell })),
  );

const cellAnimDelay = Array(25)
  .fill(1)
  .map(
    (i, index) => `
      button:nth-child(${index}) span {
        animation-duration: ${getRandom(0.1, 0.5)}s;
      }
    `,
  )
  .join('');

const Board: FC<IBoardProps> = ({
  isGamePlaying,
  data = [],
  disabled = false,
  onBet = () => {},
  nextTile,
  openedCount = 0,
  mines = 0,
}) => {
  const [cells, setCells] = useState(initialRows);
  const [countCells, setCountCells] = useState(25);

  const handleClick = (coords: [r: number, c: number]) => {
    onBet(coords);
  };

  const calcCells = useMemo(
    () => data.reduce((acc, row) => acc + row.length, 0) - mines,
    [mines, data],
  );

  useEffect(() => {
    if (data?.length)
      setCells(() => {
        setCountCells(calcCells);
        return data;
      });
  }, [data, calcCells]);

  return (
    <SWrapper isPlaying={isGamePlaying}>
      {isGamePlaying && (
        <InfoBoard
          nextTile={nextTile}
          openedCount={openedCount}
          count={countCells}
        />
      )}

      <SInner disabled={disabled} cellAnimDelay={cellAnimDelay}>
        {cells.map((row, rowIndex) =>
          row.map(
            (
              { id, name, type, multiplier, isSelected, isLast, bonus, extra },
              cellIndex,
            ) => {
              const tile = tilesArray[rowIndex][cellIndex];

              return (
                <BlockButton
                  key={cellIndex}
                  onClick={() => handleClick([rowIndex, cellIndex])}
                  title={name}
                  type={type}
                  strip={multiplier}
                  isSelected={isSelected}
                  disabled={disabled}
                  isLast={isLast}
                  isBonus={bonus}
                  tile={tile}
                  extra={extra}
                />
              );
            },
          ),
        )}
      </SInner>
    </SWrapper>
  );
};

export default Board;
