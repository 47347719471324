import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/ic_info.min.svg';
import { ReactComponent as VolumeIcon } from 'assets/icons/ic_soundon.min.svg';
import { ReactComponent as VolumeXIcon } from 'assets/icons/ic_soundoff.min.svg';
import { ReactComponent as SessionIimeIcon } from 'assets/icons/ic_session_time.svg';
import { ReactComponent as SessionIimerIcon } from 'assets/icons/ic_session_timer.svg';

const icons = {
  info: InfoIcon,
  volume: VolumeIcon,
  'volume-x': VolumeXIcon,
  session_time: SessionIimeIcon,
  session_timer: SessionIimerIcon,
};

const StyledIcon = styled.i`
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
`;

const Icon: FC<{ name: keyof typeof icons }> = ({ name }) => {
  const IconComp = icons[name];
  return (
    <StyledIcon>
      <IconComp />
    </StyledIcon>
  );
};

export default Icon;
