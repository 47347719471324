import styled from 'styled-components';

import roofSrc from 'assets/images/roof.min.png';

export const SHeader = styled.header`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  height: var(--header-height);
  z-index: 1;

  [data-box='toasts'] {
    display: none;
    z-index: 100;
  }

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    [data-box='informer'] {
      display: flex;
    }

    [data-box='toasts'] {
      display: block;
      margin: 50px 0 0 -5px;
    }
  }

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    [data-box='toasts'] {
      margin: 30px 0 0 -15px;
    }
  }
`;

export const SRoof = styled.span`
  position: absolute;
  background: none no-repeat 50% 100%;
  background-size: 100% auto;
  background-image: url('${roofSrc}');
  width: 111%;
  height: 100%;
  bottom: -15%;
  pointer-events: none;
`;
