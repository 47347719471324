import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'i18n/Key';

import { SWrapper, SList, SItem, SLabel, SValue } from './style';
import { IFreebetsProps } from './types';

const Freebets: FC<IFreebetsProps> = ({
  data: { total_win, amount_left, coin_value },
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SList>
        <SItem>
          <SLabel>{t(Key.freeBets)}</SLabel>
          <SValue>{amount_left}</SValue>
        </SItem>
        <SItem>
          <SLabel>{t(Key.betAmount)}</SLabel>
          <SValue>
            {currency}
            {coin_value}
          </SValue>
        </SItem>
        <SItem>
          <SLabel>{t(Key.totalWin)}</SLabel>
          <SValue>
            {currency}
            {total_win}
          </SValue>
        </SItem>
      </SList>
    </SWrapper>
  );
};

export default Freebets;
