import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'i18n/Key';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { controlsAtom, introState } from 'store';
import { useSound } from 'shared/hooks/useSound';
import { isMobile } from 'shared/helpers/isMobile';
import { BONUS_SLIDE_INDEX } from 'shared/components/Intro/introSlides';

import { SButton } from './style';

const BonusButton: FC<{ disabled: boolean }> = ({ disabled }) => {
  const setIntro = useSetRecoilState(introState);

  const { t } = useTranslation();
  const controls = useRecoilValue(controlsAtom);

  const [playBtnEnable] = useSound('btnEnable', {
    soundEnabled: controls.sound,
  });
  const [playBtnHover] = useSound('btnHover');

  const clickHandle = () => {
    playBtnEnable();
  };

  const hoverHandle = () => {
    if (!isMobile()) playBtnHover();
  };

  const handleClick = () => {
    setIntro({
      sliderIndex: BONUS_SLIDE_INDEX,
    });
  };

  return (
    <SButton
      onMouseDown={clickHandle}
      onMouseEnter={hoverHandle}
      onClick={handleClick}
      disabled={disabled}
    >
      <span>{t(Key.bonus)}</span>
    </SButton>
  );
};

export default BonusButton;
