import { atom } from 'recoil';
import { PlayStatus } from 'shared/constants/game';

export const playStatusAtom = atom<{
  status:
    | PlayStatus.REGULAR
    | PlayStatus.PLAYING
    | PlayStatus.LOSS
    | PlayStatus.WIN;
}>({
  key: 'playStatus',
  default: {
    status: PlayStatus.REGULAR,
  },
});

export const processingAtom = atom<boolean>({
  key: 'processing',
  default: false,
});
