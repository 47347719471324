import { FC } from 'react';

import { useWindowSize } from 'shared/hooks/useWindowSize';
import Intro from './Intro';
import { IntroModal } from './IntroModal';
import { SBar } from './style';
import { IIntroBarProps } from './types';

export const IntroBar: FC<IIntroBarProps> = ({ onChange }) => {
  const screen = useWindowSize();

  const handleChange = () => {
    onChange(null);
  };

  return screen && screen?.width < 992 ? (
    <IntroModal onChange={handleChange} showFlag={false} />
  ) : (
    <SBar>
      <Intro setIsIntro={handleChange} />
    </SBar>
  );
};
