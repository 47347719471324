import { useState, FC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import dayjs from 'dayjs';

import threepiClient from 'api/threepiClient';

import { exitLobby } from 'shared/helpers/handleExit';

import { modalState, spentTimeAtom } from 'store';
import { useInterval } from 'shared/hooks/useInterval';

import { IPlayingTimer } from './types';
import { useTranslation } from 'react-i18next';
import { Key } from 'i18n/Key';

const PlayingTimer: FC<IPlayingTimer> = ({
  time,
  isStarted,
  historyUrlRef,
  currency,
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);
  const spentTime = useRecoilValue(spentTimeAtom);
  const [startTime, setStartTime] = useState<number>(dayjs().valueOf());

  useInterval(() => {
    const currentTime = dayjs().valueOf();
    const counter = (currentTime - startTime) / 1000;

    if (!threepiClient.isShowRealityCheck()) {
      return;
    }

    if (time <= counter && !modal && !isStarted) {
      setModal({
        title: (
          <span>
            <span>
              {`${t(Key.realityCheck1)} ${dayjs
                .duration(currentTime - spentTime.startTime)
                .format('HH:mm:ss')}s.`}
            </span>
            <br />
            <span>{`${t(Key.realityCheck2)} `}</span>
            <span>{currency}</span>
            <span>{spentTime.bet.toFixed(2)}</span>
            <span>{` ${t(Key.realityCheck3)} `}</span>
            <span>{currency}</span>
            <span>{spentTime.won.toFixed(2)}</span>
            <br />
            <span>{t(Key.realityCheck4)}</span>
          </span>
        ),
        buttons: [
          {
            label: t(Key.keepPlaying),
            type: 'close',
            action: () => {
              setStartTime(dayjs().valueOf());
            },
          },
          {
            label: t(Key.quitTheGame),
            type: 'quit',
            action: () => {
              exitLobby(historyUrlRef.current);
            },
          },
        ],
      });
    }
  }, 1000);

  return null;
};

export default PlayingTimer;
