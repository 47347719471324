import { atom } from 'recoil';

export const controlsAtom = atom<{
  sound: boolean;
  isPausedMainTheme: boolean;
}>({
  key: 'controls',
  default: {
    sound: true,
    isPausedMainTheme: false,
  },
});
