import { atom } from 'recoil';

export const statesAtom = atom<{
  paused: boolean;
}>({
  key: 'states',
  default: {
    paused: false,
  },
});
