import { SWrapper } from './style';
import explosionSrc from 'assets/images/explosion.min.png';
import imageStore from 'store/imageStore';

const DynamiteAnim = () => {
  return (
    <SWrapper>
      <img alt="" src={imageStore.getImage(explosionSrc)} />
    </SWrapper>
  );
};

export default DynamiteAnim;
