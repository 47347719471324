import styled from 'styled-components';

export const SWrapper = styled.div`
  flex: 0 0 100%;
  padding: 0 15px 0 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
  font-size: 13px;
`;

export const SItem = styled.div`
  color: var(--btn-bg-cashout-active);
  padding: 1px 8px 1px 5px;
  border-radius: 12px;

  i {
    width: 12px;
    height: 12px;
  }
  i svg {
    width: 12px;
    height: 12px;
    transform: translateY(-2px);
  }
`;

export const SValue = styled.span`
  display: inline-block;
  margin-left: 6px;

  &[data-min-width] {
    min-width: 56px;
  }
`;
