import { IKeyLaunchParams, IAccountParams } from '../global';

const mappedParams = {
  path: 'path',
  clientName: 'clientName',
  lobbyurl: 'lobbyURL',
  operatorid: 'operatorID',
  operatorbrandid: 'operatorBrandID',
  gameid: 'gameID',
  NYX_GCM_ENV: 'NYX_GCM_ENV',
  NYX_GCM_PARAMS: 'NYX_GCM_PARAMS',
  sessionid: 'sessionID',
  jurisdiction: 'jurisdiction',
  regulatoryURL: 'regulatoryURL',
  rcLimit: 'rcLimit',
  rcElapsed: 'rcElapsed',
  rcExitURL: 'rcExitURL',
  historyurl: 'rcHistoryURL',
  hideHome: 'hideHome',
  currency: 'currencyCode',

  environment: 'environment',
  playMode: 'playMode',
  lang: 'lang',
} as const;

type TKeyUrlParams = keyof typeof mappedParams;
type TUrlParams = {
  [key in TKeyUrlParams]: boolean | number | string;
};

type TInitParams = {
  [key in IKeyLaunchParams]?: boolean | number | string;
};

class ThreepiClient {
  private isInitialized: boolean = true;
  private observers: { [key: string]: ((...args: any) => void)[] } = {};
  clientAPI = window.clientAPI;

  init = async (launchParams: { [key: string]: boolean | number | string }) => {
    const initParams = Object.keys(
      launchParams as TUrlParams,
    ).reduce<TInitParams>((acc, key) => {
      const k = key as keyof typeof mappedParams;

      if (mappedParams[k]) {
        acc[mappedParams[k]] = launchParams[k];
      }
      return acc;
    }, {});

    this.isInitialized = await window.clientAPI?.init(initParams);

    this.initEvents();
  };

  configure = async (accountParams: IAccountParams) => {
    await window.clientAPI?.configure(accountParams);
  };

  addCallback = (
    type: string,
    modeOn: string,
    modeOff: string | null = null,
  ) => {
    window.clientAPI?.callbacks[modeOn].add((arg: any) => {
      this.observers[type]?.forEach(cb => cb(true, arg));
    });

    if (modeOff) {
      window.clientAPI?.callbacks[modeOff].add((arg: any) => {
        this.observers[type]?.forEach(cb => cb(false, arg));
      });
    }
  };

  initEvents = () => {
    const { addCallback } = this;

    addCallback('audio', 'audio-enable', 'audio-disable');
    addCallback('help', 'help-open', 'help-close');
    addCallback('paytable', 'paytable-open', 'paytable-close');
    addCallback('history', 'history-open', 'history-close');
    addCallback('pause', 'game-pause', 'game-resume');
    addCallback('sync-balance', 'sync-balance');
    addCallback('update-balance', 'update-balance');
  };

  on = (type: string, callback: (...args: any) => void) => {
    if (!this.observers[type]) {
      this.observers[type] = [];
    }

    if (!this.observers[type].includes(callback)) {
      this.observers[type].push(callback);
    }
  };

  off = (type: string, callback: (...args: any) => void) => {
    if (!this.observers[type]) return;

    const observerIndex = this.observers[type].indexOf(callback);

    if (observerIndex > -1) {
      this.observers[type].splice(observerIndex, 1);
    }
  };

  startLoad = () => {
    window.clientAPI?.send('load-start');
  };

  setProgressLoad = (value: number) => {
    window.clientAPI?.send('load-progress', Math.floor(value));
  };

  endLoad = () => {
    window.clientAPI?.send('load-end');
  };

  startGame = () => {
    window.clientAPI?.send('play-start');
    window.clientAPI?.send('value-win', 0);
  };

  endGame = () => {
    window.clientAPI?.send('play-end');
  };

  noFunds = () => {
    window.clientAPI?.send('no-funds');
  };

  updateBalance = (
    value: number,
    stake: number,
    mode: 'start' | 'end' = 'start',
  ) => {
    window.clientAPI?.send('value-balance', {
      balance: value,
      mode: mode === 'start' ? 'place' : 'settle',
      stake: stake, // 'place' start of round | 'settle' end of round
    });
  };

  updateBet = (value: number) => {
    window.clientAPI?.send('value-stake', value);
  };

  updateWin = (value: number) => {
    window.clientAPI?.send('value-win', value);
  };

  updateSound = (state: boolean) => {
    if (state) {
      window.clientAPI?.send('audio-enable');
    } else {
      window.clientAPI?.send('audio-disable');
    }
  };

  exitGame = () => {
    window.clientAPI?.send('game-close');
  };

  updateGameHelp = (state: boolean) => {
    if (state) {
      window.clientAPI?.send('help-open');
    } else {
      window.clientAPI?.send('help-close');
    }
  };

  updatePaytable = (state: boolean) => {
    if (state) {
      window.clientAPI?.send('paytable-open');
    } else {
      window.clientAPI?.send('paytable-close');
    }
  };

  updateBetsHistory = (state: boolean) => {
    if (state) {
      window.clientAPI?.send('history-open');
    } else {
      window.clientAPI?.send('history-close');
    }
  };

  isShowRealityCheck = () => {
    return window.clientAPI &&
      window.clientAPI.showInGameRealityCheck !== undefined
      ? window.clientAPI.showInGameRealityCheck
      : true;
  };

  isShowInGameErrors = () => {
    return window.clientAPI && window.clientAPI.showInGameErrors !== undefined
      ? window.clientAPI.showInGameErrors
      : true;
  };

  handleError = (error: { status: number; message: string }) => {
    const { status, message } = error;
    let code = status;

    if (code > 1000) {
      code = -Math.abs(code - 1000);
    }

    window.clientAPI?.send('error', [code, message]);
  };
}

const threepiClient = new ThreepiClient();

export default threepiClient;
