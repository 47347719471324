import { ReactNode } from 'react';
import { atom } from 'recoil';

export interface IModalButton {
  label: string;
  type: 'reload' | 'close' | 'quit' | 'history' | 'continue' | 'lobby';
  action?: () => void | boolean;
}

export interface IModalState {
  title?: ReactNode;
  message?: ReactNode;
  buttons?: IModalButton[];
  needAuth?: boolean;
  needClearAuth?: boolean;
}

export const modalState = atom<IModalState | null>({
  key: 'modal',
  default: null,
});
