import img_game_logo from 'assets/images/game_logo.min.png';
import mPlayerWrapper, {
  PlayerListener,
  Animation,
} from 'spine-player/spine-player-wrapper';

import board from 'assets/images/board.min.png';
import roof from 'assets/images/roof.min.png';

import t1 from 'assets/images/buttonTiles/t1.min.png';
import t2 from 'assets/images/buttonTiles/t2.min.png';
import t3 from 'assets/images/buttonTiles/t3.min.png';
import t4 from 'assets/images/buttonTiles/t4.min.png';
import t5 from 'assets/images/buttonTiles/t5.min.png';
import t6 from 'assets/images/buttonTiles/t6.min.png';
import t7 from 'assets/images/buttonTiles/t7.min.png';
import t8 from 'assets/images/buttonTiles/t8.min.png';
import t9 from 'assets/images/buttonTiles/t9.min.png';
import t10 from 'assets/images/buttonTiles/t10.min.png';
import t11 from 'assets/images/buttonTiles/t11.min.png';
import t12 from 'assets/images/buttonTiles/t12.min.png';
import t13 from 'assets/images/buttonTiles/t13.min.png';
import t14 from 'assets/images/buttonTiles/t14.min.png';
import t15 from 'assets/images/buttonTiles/t15.min.png';
import t16 from 'assets/images/buttonTiles/t16.min.png';
import t17 from 'assets/images/buttonTiles/t17.min.png';
import t18 from 'assets/images/buttonTiles/t18.min.png';
import t19 from 'assets/images/buttonTiles/t19.min.png';
import t20 from 'assets/images/buttonTiles/t20.min.png';
import t21 from 'assets/images/buttonTiles/t21.min.png';
import t22 from 'assets/images/buttonTiles/t22.min.png';
import t23 from 'assets/images/buttonTiles/t23.min.png';
import t24 from 'assets/images/buttonTiles/t24.min.png';
import t25 from 'assets/images/buttonTiles/t25.min.png';

import mine from 'assets/images/mine.min.png';
import prize_1 from 'assets/images/prize_1.min.png';
import prize_2 from 'assets/images/prize_2.min.png';
import prize_3 from 'assets/images/prize_3.min.png';
import img_explosion from 'assets/images/explosion.min.png';
import sound_button_enable from 'assets/sounds/button_enable.mp3';
import sound_button_hover from 'assets/sounds/button_hover.mp3';
import sound_dynamite_explosion from 'assets/sounds/dynamite_explosion.mp3';
import sound_game_starter from 'assets/sounds/game_starter.mp3';
import sound_gold_reveal from 'assets/sounds/gold_reveal.mp3';
import sound_stone_click from 'assets/sounds/stone_click.mp3';
import sound_stone_hover from 'assets/sounds/stone_hover.mp3';
import sound_win from 'assets/sounds/win.mp3';
import xmines_main_theme from 'assets/sounds/xmines_main_theme.mp3';
import xmines_nathanyell_exited from 'assets/sounds/xmines_nathanyell_exited.mp3';
import xmines_nathanyell_frustrated from 'assets/sounds/xmines_nathanyell_frustrated.mp3';
import xmines_nathanyell_happy from 'assets/sounds/xmines_nathanyell_happy.mp3';
import xmines_nathanyell_sleepy from 'assets/sounds/xmines_nathanyell_sleepy.mp3';

import { TFiles } from './types';

export const files: TFiles = {
  image: [
    img_game_logo,
    board,
    roof,
    t1,
    t2,
    t3,
    t4,
    t5,
    t6,
    t7,
    t8,
    t9,
    t10,
    t11,
    t12,
    t13,
    t14,
    t15,
    t16,
    t17,
    t18,
    t19,
    t20,
    t21,
    t22,
    t23,
    t24,
    t25,
    mine,
    prize_1,
    prize_2,
    prize_3,
  ],
  fetch: [
    sound_button_enable,
    sound_button_hover,
    sound_dynamite_explosion,
    sound_game_starter,
    sound_gold_reveal,
    sound_stone_click,
    sound_stone_hover,
    sound_win,
    xmines_main_theme,
    xmines_nathanyell_exited,
    xmines_nathanyell_frustrated,
    xmines_nathanyell_happy,
    xmines_nathanyell_sleepy,
  ],
  imageToBlob: [img_explosion],
  callback: [
    cb => {
      setTimeout(() => {
        cb();
      }, 1000);
    },
    cb => {
      const listener: PlayerListener = {
        onReady() {
          mPlayerWrapper.unsubscribeListener(listener);
          cb();
        },

        onAnimationStart(animation: Animation) {},
      };

      mPlayerWrapper.subscribeListener(listener);
    },
  ],
};
