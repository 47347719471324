import styled from 'styled-components';
import corner1Src from 'assets/images/corner1.min.png';
import corner2Src from 'assets/images/corner2.min.png';

export const SWrapper = styled.section`
  max-width: 600px;
  margin: 0 auto;
  transform: translate(calc(var(--sidebar-width) / 2), 0);
  position: relative;
  z-index: 10;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    padding: 0 10px;
    transform: translate(0, 0);
  }
`;

export const SLogo = styled.img`
  max-width: calc(100% - 40px);
  margin-left: 20px;
  position: absolute;
  margin-top: -155px;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    display: none;
  }
`;

export const SSidebar = styled.aside`
  position: absolute;
  top: 0;
  width: 340px;
  z-index: 1;
  margin-left: -350px;
  user-select: none;
  margin-top: var(--header-height);

  [data-box='intro'] {
    max-height: 660px;
  }

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    position: relative;
    margin-left: 0;
    width: 100%;
    margin-top: 0;

    [data-box='toasts'] {
      display: none;
    }
  }

  @media (max-width: 540px) and (max-aspect-ratio: 1/1) {
    margin-bottom: 40px;
  }
`;

export const SContent = styled.section``;

export const SLobbyLink = styled.a`
  position: absolute;
  left: 50%;
  display: inline-block;
  padding: 4px 6px 3px;
  background: var(--btn-bg-active);
  color: var(--inverse-color);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  transform: translate(-50%, -14px);
  cursor: pointer;

  &:hover {
    background: var(--btn-bg-hover);
  }
`;

export const SWinInfo = styled.div`
  color: var(--btn-bg-cashout-active);
  padding: 5px 15px 20px;
  min-height: 50px;
  font-size: 15px;
  text-align: center;
`;

export const SDownTimerText = styled.div`
  color: var(--info-c-highlight);
  text-align: center;
  font-size: 15px;
  margin: -10px 0 10px;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    margin: 0 0 4px;
  }
`;

export const SBox = styled.section`
  position: relative;
  flex: 0 0 100%;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  background-image: url(${corner1Src}), url(${corner2Src});
  background-repeat: no-repeat, no-repeat;
  background-position: 5px calc(100% - 5px), calc(100% - 5px) calc(100% - 5px);
  background-size: 100px auto, 100px auto;

  &:after {
    position: absolute;
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    background: linear-gradient(var(--board-color-1), var(--board-color-2));
  }

  &[data-box='informer'] {
    background-image: none !important;
  }

  &[data-box='informer-header'] {
    background: none;
    display: none;
    padding: 10px 0;

    button {
      background: none;
      flex: 0 1;
    }

    button:first-child {
      flex: 0 1 !important;
      margin-right: auto;
      min-width: 150px;
    }
  }

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    border-radius: 4px;

    &[data-box='informer'] {
      display: none;
    }

    &[data-box='informer-header'] {
      display: block;
      padding: 10px 0 0 10px;

      button {
        flex: 0 0 45px !important;
        min-height: 45px;
        padding: 6px 0;
      }

      &:after {
        display: none;
      }
    }
  }

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    padding: 10px;

    &[data-box='informer-header'] {
      padding: 0;
    }
  }
`;
