import { configure, send, on } from '@rlx/feim';

let isConfigured = false;

export const setRelaxConfig = ({ currency }: { currency: string }) => {
  configure({
    // The received response from /getclientconfig/, or an object adhering to its format
    // (Relax game server users only):
    // clientConfig: clientconfigResponse,
    // The casino login response, or an object adhering to its format
    // (Relax game server users only):
    // loginResponse: casinoLoginResponse,

    // When clientConfig and loginResponse cannot be provided
    // (only when not using the Relax game server):
    p2pConfig: { currency: currency },

    /* If set to true, FEIM will handle the responsibilities of RgPostMessageAPI library.
    If true, RgPostMessageAPI library or any equivalent/duplicate integration must be
    removed from the game. */
    handleRgPostMessageAPI: true,

    /* Some jurisdictions require that the game needs to wait for a user confirmation when
    a bonus game is reached. Set this to false if the game handles it internally. */
    handleFeaturePause: true,

    // Optional, see showError:
    // showErrorMessageCallback: MY_GAME.showInGameErrorMessage,
  });
  isConfigured = true;
};

setRelaxConfig({ currency: '' });

export const startLoad = () => {
  send.gameLoadStarted();
};

export const setProgressLoad = (percent: number) => {
  send.gameLoadProgress(percent);
};

export const endLoad = () => {
  send.gameLoadCompleted();
};

export const startGame = (balance: number) => {
  if (isConfigured)
    send.roundStarted({
      balance: balance * 100,
    });
};

export const endGame = (balance: number, win: number) => {
  if (isConfigured)
    send.roundFinished({
      balance: balance * 100,
      win: { win: win * 100 },
    });
};

export const updateBalance = (balance: number) => {
  if (isConfigured) send.balanceUpdate(balance * 100);
};

export const updateBet = (bet: number) => {
  if (isConfigured) send.betUpdate(bet * 100);
};

export const updateWin = (win: number) => {
  if (isConfigured)
    send.winUpdate({
      win: win * 100,
    });
};

export const updateSound = (status: boolean = true) => {
  send.updateSettings({ sounds: status });
};

export const exitGame = () => {
  send.exitGame();
};

updateSound();

let relaxEventer: boolean = false;

type TFunc =
  | 'updateSettings'
  | 'refreshBalance'
  | 'toggleGameHelp'
  | 'togglePaytable';

export const listenEvents = (
  cb: (
    on: {
      [key in TFunc]: (cb: (data?: any) => void) => void;
    },
  ) => void,
) => {
  if (!relaxEventer) {
    relaxEventer = true;

    cb(on);
  }
};
