import t1 from 'assets/images/buttonTiles/t1.min.png';
import t2 from 'assets/images/buttonTiles/t2.min.png';
import t3 from 'assets/images/buttonTiles/t3.min.png';
import t4 from 'assets/images/buttonTiles/t4.min.png';
import t5 from 'assets/images/buttonTiles/t5.min.png';
import t6 from 'assets/images/buttonTiles/t6.min.png';
import t7 from 'assets/images/buttonTiles/t7.min.png';
import t8 from 'assets/images/buttonTiles/t8.min.png';
import t9 from 'assets/images/buttonTiles/t9.min.png';
import t10 from 'assets/images/buttonTiles/t10.min.png';
import t11 from 'assets/images/buttonTiles/t11.min.png';
import t12 from 'assets/images/buttonTiles/t12.min.png';
import t13 from 'assets/images/buttonTiles/t13.min.png';
import t14 from 'assets/images/buttonTiles/t14.min.png';
import t15 from 'assets/images/buttonTiles/t15.min.png';
import t16 from 'assets/images/buttonTiles/t16.min.png';
import t17 from 'assets/images/buttonTiles/t17.min.png';
import t18 from 'assets/images/buttonTiles/t18.min.png';
import t19 from 'assets/images/buttonTiles/t19.min.png';
import t20 from 'assets/images/buttonTiles/t20.min.png';
import t21 from 'assets/images/buttonTiles/t21.min.png';
import t22 from 'assets/images/buttonTiles/t22.min.png';
import t23 from 'assets/images/buttonTiles/t23.min.png';
import t24 from 'assets/images/buttonTiles/t24.min.png';
import t25 from 'assets/images/buttonTiles/t25.min.png';

export const tilesArray: string[][] = [
  [t1, t2, t3, t4, t5],
  [t6, t7, t8, t9, t10],
  [t11, t12, t13, t14, t15],
  [t16, t17, t18, t19, t20],
  [t21, t22, t23, t24, t25],
];
