import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { exitLobby, openHistoryUrl } from 'shared/helpers/handleExit';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { client, terminate } from 'api/client';
import { IModalButton, modalState } from 'store';
import 'assets/css/index.css';
import 'swiper/swiper.scss';
import App from './App';
import './i18n/config';
import { Key } from './i18n/Key';
import threepiClient from 'api/threepiClient';
import { getUrlParams } from 'shared/helpers/parseUrl';

dayjs.extend(duration);
SwiperCore.use([Navigation, Pagination]);

const Index = () => {
  const urlParams = getUrlParams();
  const [isThreePILooaded, setThreePILooaded] = useState<boolean>(false);
  const [, setModal] = useRecoilState(modalState);
  const historyUrlRef = useRef({
    history_url: '',
    lobby_url: '',
    customer: '',
    hasLobbyButton: false,
  });

  const { t } = useTranslation();
  const navigateToHistoryUrl = () => {
    openHistoryUrl(historyUrlRef.current?.history_url);
  };

  const navigateToLobbyUrl = () => {
    exitLobby(historyUrlRef.current);
  };

  useEffect(() => {
    client.interceptors.response.use(
      res => {
        const { status, error, message, title, buttons } = res.data;

        if (
          // if request url is /history
          (res.config.url === '/history' && res.status === 200) ||
          [200, 0].includes(status)
        ) {
          return res;
        }

        let mTitle = title || error?.name || '';
        let errorMessage = message || '';
        let mButtons: IModalButton[] = [
          {
            label: t(Key.reload),
            type: 'reload',
          },
        ];

        const handle911 = () => {
          if (!buttons || !Array.isArray(buttons) || !buttons.length) {
            return;
          }

          let newButtons: IModalButton[] = [];
          buttons.forEach(b => {
            if (!b) {
              return;
            }

            newButtons.push({
              label: b.text || t(Key.continue),
              type: b.action || 'reload',
              action:
                b.action === 'history'
                  ? navigateToHistoryUrl
                  : b.action === 'lobby' || b.action === 'quit'
                  ? navigateToLobbyUrl
                  : undefined,
            });
          });

          if (newButtons.length) {
            mButtons = newButtons;
          }
        };

        switch (status) {
          case 912:
            threepiClient.noFunds();
            handle911();
            break;
          case 911:
            handle911();
            break;
          case 804:
            errorMessage = t(Key.timeoutDesc);
            mTitle = t(Key.timeout);
            break;
        }

        if (!threepiClient.isShowInGameErrors()) {
          return res;
        }
        threepiClient.handleError({ status, message });

        setModal({
          title: mTitle,
          message: errorMessage,
          buttons: mButtons,
        });

        return res;
      },
      () => {
        setModal({
          title: t(Key.connectionError),
          message: '',
          buttons: [
            {
              label: t(Key.continue),
              type: 'close',
            },
          ],
        });
      },
    );
  }, [setModal, t]);

  const handleTabClosing = () => {
    terminate();
  };

  const loadScript = async (gameURL: string) => {
    return new Promise((res, rej) => {
      const script = document.createElement('script');
      script.src = gameURL || '';
      script.async = false;
      script.addEventListener('load', () => {
        res(true);
      });
      document.head.appendChild(script);
    });
  };

  useEffect(() => {
    const { ext_params, currency, play_for_fun, language, staging, customer } =
      urlParams;

    const isOneXTwo = customer === 'onextwo';
    const isStaging = staging === 'true';

    if (isOneXTwo) {
      loadScript(
        isStaging
          ? 'https://www.1x2-cloud-1.com/f1x2games/infrastr_generic-framework/clientAPI.game.min.js'
          : 'https://www.1x2gamingcdn.com/f1x2games/infrastr_generic-framework/clientAPI.game.min.js',
      ).then(res => {
        threepiClient.init(
          Object.assign(ext_params, {
            environment: staging === 'true' ? 'staging' : 'production',
            playMode: play_for_fun ? 'demo' : 'real',
            lang: language,
            currency,
          }),
        );
        setThreePILooaded(true);
      });
    } else {
      setThreePILooaded(true);
    }

    window.addEventListener('beforeunload', handleTabClosing);
    return () => {
      window.removeEventListener('beforeunload', handleTabClosing);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isThreePILooaded ? <App historyUrlRef={historyUrlRef} /> : <div></div>;
};

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Index />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
