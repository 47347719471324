import mSpine from '../deps';
import {
  CHARACTER_ANIMATION_ATLAS,
  CHARACTER_ANIMATION_JSON,
  CharacterAnimations,
} from 'shared/constants/characterAnimations';

export interface PlayerListener {
  onReady(): void;
  onAnimationStart(animation: Animation): void;
}

export enum Animation {
  IDLE = 'idle',
  WIN = 'win',
  WIN_2 = 'win2',
  LOSE = 'lose',
  YAWN = 'yawn',
}

const playerWrapper = () => {
  const listeners: PlayerListener[] = [];
  const idlesToYawn = 10;
  let isAttached = false;
  let player: any = null;
  let ready = false;
  let error = false;
  let animationState: any = null;
  let idleCount = 0;
  let currentTrack: any = null;

  const animationListener = {
    /** Invoked when this entry has been set as the current entry. */
    start: (entry: any) => {
      currentTrack = entry;
    },

    /** Invoked when another entry has replaced this entry as the current entry. This entry may continue being applied for
     * mixing. */
    interrupt: (entry: any) => {},

    /** Invoked when this entry is no longer the current entry and will never be applied again. */
    end: (entry: any) => {},

    /** Invoked when this entry will be disposed. This may occur without the entry ever being set as the current entry.
     * References to the entry should not be kept after dispose is called, as it may be destroyed or reused. */
    dispose: (entry: any) => {},

    /** Invoked every time this entry's animation completes a loop. */
    complete: (entry: any) => {
      try {
        if (entry && entry.loop) {
          idleCount++;

          if (idleCount >= idlesToYawn) {
            idleCount = 0;
            player.setAnimation(CharacterAnimations.Yawn, false);
            notifyAnimationStart(Animation.YAWN);
          }
          return;
        }

        if (currentTrack !== entry) {
          return;
        }

        player.setAnimation(CharacterAnimations.Idle, true);
        notifyAnimationStart(Animation.IDLE);
        idleCount = 0;
      } catch (e) {}
    },

    /** Invoked when this entry's animation triggers an event. */
    event: (entry: any) => {},
  };

  const spineConfig = {
    jsonUrl: CHARACTER_ANIMATION_JSON,
    atlasUrl: CHARACTER_ANIMATION_ATLAS,
    animation: CharacterAnimations.Idle,
    alpha: true,
    premultipliedAlpha: false,
    showControls: false,
    showLoading: false,
    viewport: {
      x: -280,
      y: -200,
      width: 560,
      height: 380,
      padLeft: 0,
      padRight: 0,
      padTop: 0,
      padBottom: 10,
    },
    success: (spinePlayer: any) => {
      ready = true;
      if (animationState) {
        animationState.removeListener(animationListener);
      }
      animationState = player.animationState;
      if (animationState) {
        animationState.addListener(animationListener);
      }
      listeners.forEach(l => l.onReady());
    },
    error: (spinePlayer: any, msg: string) => {
      ready = true;
      error = true;
      listeners.forEach(l => l.onReady());
      detach();
    },
  };

  const notifyAnimationStart = (animation: Animation) => {
    if (!ready || !isAttached) {
      return;
    }
    listeners.forEach(l => l.onAnimationStart(animation));
  };

  const playAnimation = (animation: Animation): boolean => {
    if (!isAttached) {
      return false;
    }

    switch (animation) {
      case Animation.LOSE:
        player.setAnimation(CharacterAnimations.Lose, false);
        notifyAnimationStart(Animation.LOSE);
        break;
      case Animation.WIN:
        player.setAnimation(CharacterAnimations.Win, false);
        notifyAnimationStart(Animation.WIN);
        break;
      case Animation.WIN_2:
        player.setAnimation(CharacterAnimations.Win2, false);
        notifyAnimationStart(Animation.WIN_2);
        break;
    }

    idleCount = 0;
    return true;
  };

  const resetIdleCount = () => {
    idleCount = 0;
  };

  const subscribeListener = (listener: PlayerListener) => {
    if (listeners.indexOf(listener) >= 0) {
      return;
    }
    listeners.push(listener);
    if (ready) {
      listener.onReady();
    }
  };

  const unsubscribeListener = (listener: PlayerListener) => {
    listeners.filter(l => l !== listener);
  };

  const attach = (parent: string | HTMLElement) => {
    if (isAttached) {
      detach();
    }

    if (error) {
      return;
    }

    const mParent =
      typeof parent === 'string' ? document.getElementById(parent) : parent;

    if (player === null) {
      player = new mSpine.SpinePlayer(mParent, spineConfig);
    } else {
      player.parent = mParent;
      player.parent.appendChild(player.dom);
      player.play();
    }

    isAttached = true;
  };

  const detach = () => {
    if (player === null || !isAttached) {
      return;
    }
    player.pause();
    player.parent.removeChild(player.dom);
    isAttached = false;
  };

  return {
    attach,
    detach,
    subscribeListener,
    unsubscribeListener,
    playAnimation,
    resetIdleCount,
  };
};

const mPlayerWrapper = playerWrapper();
mPlayerWrapper.attach(document.createElement('div'));
export default mPlayerWrapper;
