import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

import Intro from './Intro';
import { SInner, SWrapper } from './style';

export const IntroModal: FC<{
  onChange: (status: boolean, isFlag: boolean) => void;
  showFlag?: boolean;
}> = ({ onChange, showFlag = true }) => {
  const portalEL = document.getElementById('portal-root');

  useEffect(() => {
    const elHtml = document.querySelector('html');
    if (elHtml) elHtml.classList.add('scroll-block');

    return () => {
      if (elHtml) elHtml.classList.remove('scroll-block');
    };
  }, []);

  return (
    portalEL &&
    createPortal(
      <SWrapper>
        <SInner>
          <Intro setIsIntro={onChange} showFlag={showFlag} />
        </SInner>
      </SWrapper>,
      portalEL,
    )
  );
};
