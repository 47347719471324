import { FC } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import * as relax from 'api/relaxClient';
import threepiClient from 'api/threepiClient';

import { controlsAtom, introState } from 'store';
import Button, { Label, Text } from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Span from 'shared/components/Span';
import { betsListState } from 'shared/components/BetsList';

import { IInformerProps } from './types';
import { StyledInformer } from './style';
import { useTranslation } from 'react-i18next';
import { Key } from 'i18n/Key';

const Informer: FC<IInformerProps> = ({ balance, currency }) => {
  const { t } = useTranslation();
  const [controls, setControls] = useRecoilState(controlsAtom);
  const setIntro = useSetRecoilState(introState);
  const setBetsList = useSetRecoilState(betsListState);

  const clickSound = () => {
    setControls(prev => {
      relax.updateSound(!prev.sound);
      threepiClient.updateSound(!prev.sound);

      return {
        ...prev,
        sound: !prev.sound,
      };
    });
  };

  const showIntro = () => {
    threepiClient.updateGameHelp(true);

    setIntro({
      tab: 'tutorial',
    });
  };

  const handleBetsList = () => {
    threepiClient.updateBetsHistory(true);

    setBetsList({
      isActive: true,
      isLoading: true,
    });
  };

  return (
    <StyledInformer>
      <Button
        onClick={handleBetsList}
        style={{
          flex: '0 0 calc(100% - 160px)',
          alignItems: 'flex-start',
        }}
      >
        <Label>{t(Key.balance)}</Label>
        <Text
          style={{
            textAlign: 'left',
          }}
        >
          <Span hexCode={currency} />
          <span>{balance}</span>
        </Text>
      </Button>

      <Button style={{ flex: '0 0 70px' }} onClick={showIntro}>
        <Text>
          <Icon name="info" />
        </Text>
      </Button>

      <Button
        isInverseClickSound
        style={{ flex: '0 0 70px' }}
        onClick={clickSound}
      >
        <Text>
          <Icon name={controls.sound ? 'volume' : 'volume-x'} />
        </Text>
      </Button>
    </StyledInformer>
  );
};

export default Informer;
