import styled, { css, keyframes } from 'styled-components';

import coinSrc1 from 'assets/images/prize_1.min.png';
import coinSrc2 from 'assets/images/prize_2.min.png';
import coinSrc3 from 'assets/images/prize_3.min.png';

import mineSrc from 'assets/images/mine.min.png';
import explosionSrc from 'assets/images/mine.min.png';
import bonusSrc from 'assets/icons/bonus_label.min.png';

const peelOutBlock = keyframes`
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  40% {
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 50%, 100% 100%, 100% 100%, 0 100%);
    opacity: 0;
  }

`;

const peelOutCover = keyframes`
  0% {
    height: 0;
    transform: rotate(0deg);
    clip-path: polygon(0 50%, 100% 0%, 100% 0%, 100% 100%, 0 100%);
  }

  40% {
    opacity: 1;
  }

  100% {
    height: calc(100% - 10px);
    transform: rotate(52deg);
    clip-path: polygon(0 50%, 100% 0%, 100% 0%, 100% 100%, 0 100%);
    opacity: 0;
  }
`;

const tileStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 100px;
  max-height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const SButton = styled.button`
  flex: 0 0 20%;
  position: relative;
  display: block;
  border: none;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 3px;
  background: none;
  cursor: pointer;
  user-select: none;
  transition: transform 0.1s;
  z-index: 10;
  padding-top: 20%;

  &:not(:disabled):not(.is-mobile):hover {
    transform: scale(1.08);
  }

  &:disabled {
    cursor: default;
    z-index: 10;
  }

  &.is-last {
    z-index: 11;
  }

  &:before,
  &:after {
    content: '';
    ${tileStyle};
    opacity: 0.4;
    visibility: hidden;
    background-image: url('${mineSrc}');
  }

  &:after {
    background-image: url('${coinSrc1}');
  }
  [data-prize='2']&:after {
    background-image: url('${coinSrc2}');
  }
  [data-prize='3']&:after {
    background-image: url('${coinSrc3}');
  }

  &.is-dynamite:before,
  &.is-gold:after {
    visibility: visible;
  }

  &.selected.is-dynamite:before,
  &.selected.is-gold:after {
    opacity: 1;
  }
`;

export const SStone = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  z-index: 22;

  img {
    ${tileStyle};
    pointer-events: none;
    will-change: clip-path, opacity, height, transform;
    overflow: hidden;
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    z-index: 101;
    background: rgb(169, 72, 87);
    overflow: hidden;
    height: 0;
    width: calc(100% - 10px);
    margin: 5px;
    transform-origin: 0 50%;
  }

  &.opened {
    &:after {
      animation-name: ${peelOutCover};
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
    }

    img {
      animation-name: ${peelOutBlock};
      animation-fill-mode: forwards;
      animation-duration: 0.7s;
    }
  }
`;

export const SExplosion = styled.span`
  ${tileStyle};
  opacity: 0;
  background-image: url('${explosionSrc}');

  &.show {
    opacity: 1;
  }
`;

export const SBox = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  top: 0;
  left: 0;
  z-index: 10;

  &.selectedGold {
    &:before {
      content: '';
      position: absolute;
      top: 20%;
      left: 30%;
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      background: rgb(255, 245, 168);
      filter: blur(15px);
      animation: goldPulse ease-in-out 2s forwards;
    }
  }
`;

export const SStrip = styled.span`
  display: block;
  position: absolute;
  background: var(--strip-bg);
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  height: 26px;
  line-height: 26px;
  bottom: 5%;
  left: 5%;
  width: 90%;
  animation: fadeOut 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    height: 22px;
    line-height: 22px;
  }

  @media (max-width: 350px) and (max-aspect-ratio: 1/1) {
    height: 18px;
    line-height: 18px;
    font-size: 12px;
  }
`;

export const SBonusStrip = styled.span`
  background-size: 100% auto;
  width: 36px;
  height: 45px;
  z-index: 100;
  right: 3px;
  top: 3px;
  position: absolute;
  background: none no-repeat 50% 50%;
  background-size: auto 100%;
  background-image: url('${bonusSrc}');
  pointer-events: none;

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    width: 30px;
    height: 30px;
  }
`;
