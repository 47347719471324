export const postMessageTypes = {
  PMT_GAME_BUSY: 'gameBusy',
  PMT_GAME_NOT_BUSY: 'gameNotBusy',
  PMT_GAME_LOADED: 'gameLoaded',
  PMT_GO_TO_DEPOSIT: 'goToDeposit',
  PMT_CLOSED: 'exit',
  PMT_GAME_RELOAD: 'reloadGame',
};

export const receiveMessageTypes = {
  RMT_PLAY_INTERRUPTED: 'playInterrupted',
  RMT_PLAY_RESUMED: 'playResumed',
  RMT_REFRESH_BALANCE: 'refreshBalance',
  RMT_AUTO_SPIN: 'autospin',
  RMT_SPIN: 'spin',
  RMT_SHOW_PAY_TABLE: 'showPayTable',
  RMT_SET_MAX_BET: 'maxbet',
};

class GameBridgeManager {
  private listeners: ((message: any) => void)[] = [];

  constructor() {
    window.addEventListener('message', this._onMessage.bind(this));
  }

  onLoadEnded() {
    this._send(postMessageTypes.PMT_GAME_LOADED);
  }

  gameStart() {
    this._send(postMessageTypes.PMT_GAME_BUSY);
  }

  gameEnd() {
    this._send(postMessageTypes.PMT_GAME_NOT_BUSY);
  }

  exit() {
    this._send(postMessageTypes.PMT_CLOSED);
  }

  reload() {
    this._send(postMessageTypes.PMT_GAME_RELOAD);
  }

  _onMessage(event: any) {
    console.log('::: PostMessageAPI on message', event);
    const message = event.data;

    if (!message) {
      return;
    }

    this.listeners.forEach(v => v(message));
  }

  _send(event: any) {
    const message = { event: event };
    window.parent.postMessage(JSON.stringify(message), '*');
    console.log('::: PostMessageAPI sent', message);
  }

  subscribe(listener: (message: any) => void) {
    if (this.listeners.indexOf(listener) < 0) {
      this.listeners.push(listener);
    }
  }

  unsubscribe(listener: (message: any) => void) {
    this.listeners = this.listeners.filter(v => v !== listener);
  }
}

const gameBridgeManager = new GameBridgeManager();

export default gameBridgeManager;
