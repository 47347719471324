import { FC, useEffect, useState } from 'react';
import cx from 'classnames';

import Span from '../Span';

import { SToasts, SToast } from './style';
import { IToastsProps } from './types';

const Toasts: FC<IToastsProps> = ({ value, type = 'start', currency }) => {
  const [val, setVal] = useState(-1);

  useEffect(() => {
    setVal(value);
    let intervalId: any = 0;

    if (type !== 'win')
      intervalId = setTimeout(() => {
        setVal(-1);
      }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [value, type]);

  return (
    <SToasts data-box="toasts">
      {val > 0 && (
        <SToast
          key={value}
          type={type}
          className={cx({
            toastAnim: type !== 'win',
          })}
        >
          <span>{type === 'start' ? '-' : '+'}</span>
          <Span hexCode={currency} />
          <span>{value}</span>
        </SToast>
      )}
    </SToasts>
  );
};

export default Toasts;
