import { FC, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { debounce } from 'lodash';

import dayjs from 'dayjs';
import cx from 'classnames';
import i18n from 'i18next';

import threepiClient from 'api/threepiClient';

import { auth, getTranslations } from 'api/client';
import {
  modalState,
  gridAtom,
  spentTimeAtom,
  controlsAtom,
  gameInfoAtom,
  statesAtom,
} from 'store';

import Game from 'views/Game';
import { getUrlParams } from 'shared/helpers/parseUrl';
import Modal from 'shared/components/Modal';
import BetsList from 'shared/components/BetsList';
import Preloader from 'shared/components/Preloader';

import { SWrapper, SPause } from './style';
import { IAppProps } from './types';
import { useTranslation } from 'react-i18next';
import { Key } from 'i18n/Key';
import { DEFAULT_NS } from 'i18n/config';

import { QUERY_MAX_BET, QUERY_MIN_BET } from 'shared/helpers/parseUrl';

const CUSTOMER_RELAX = 'relax';

const App: FC<IAppProps> = ({ historyUrlRef }) => {
  const { t } = useTranslation();

  const setControls = useSetRecoilState(controlsAtom);
  const [isPreloaded, setIsPreloaded] = useState<boolean>(false);
  const [gameInfo, setGameInfo] = useState<{ [key: string]: any }>({});
  const setSpentTime = useSetRecoilState(spentTimeAtom);
  const [modal, setModal] = useRecoilState(modalState);
  const states = useRecoilValue(statesAtom);
  const setGrid = useSetRecoilState(gridAtom);
  const setGameInfoA = useSetRecoilState(gameInfoAtom);

  const checkAuth = useCallback(async () => {
    setSpentTime({
      startTime: dayjs().valueOf(),
      bet: 0,
      won: 0,
    });
    const { reality_check_time, ...params } = getUrlParams();
    const customer = params.customer;
    const hasMaxBetButton = params[QUERY_MAX_BET] !== 'false';
    const hasMinBetButton = params[QUERY_MIN_BET] !== 'false';

    const data = await auth(params);
    if ([0, 200].includes(data.status)) {
      const hasLobbyButton = customer === CUSTOMER_RELAX || !!data?.lobby_url;

      threepiClient.configure(data.third_party_data || {}).then(r => {
        threepiClient.updateBalance(
          data.balance,
          data.previous_session?.bet_amount || data.default_bet_value,
          data.previous_session ? 'start' : 'end',
        );
      });

      setGameInfoA(data || {});
      setGameInfo({
        ...data,
        hasLobbyButton,
        customer,
        reality_check_time,
        hasMaxBetButton,
        hasMinBetButton,
      });
      historyUrlRef.current = {
        history_url: data?.history_url || '',
        lobby_url: data?.lobby_url || '',
        customer: customer,
        hasLobbyButton: hasLobbyButton,
      };
    } else {
      setModal({
        title: data?.title || data?.error?.name || '',
        message: data?.message || '',
        buttons: [
          {
            label: t(Key.reload),
            type: 'reload',
          },
        ],
      });
    }
  }, [setModal, setSpentTime, t, historyUrlRef, setGameInfoA]);

  const handleVisibilityChange = useCallback(() => {
    setControls(prev => ({
      ...prev,
      isPausedMainTheme: document?.hidden,
    }));
  }, [setControls]);

  const fetchTranslations = async () => {
    const data = await getTranslations();

    Object.keys(data).forEach(lang => {
      i18n.addResources(lang, DEFAULT_NS, data[lang]);
    });
  };

  const W_SIDEBAR = 350;
  const W_CONTENT = 600;
  const H_CONTENT = 660;
  const H_LOGO = 170;
  const TOP = 5;
  const W_GAME = W_SIDEBAR + W_CONTENT;
  const H_GAME = H_CONTENT + H_LOGO;
  const MAGIC_WIDHT = 612;
  const MAGIC_WIDTH_DELTA = 1;
  const MAGIC_HEIGHT_DELTA = 1.01;

  const debouncedResize = debounce(
    (elApp: HTMLElement, width: number, height: number) => {
      if (width / height > 1) {
        const scale = Math.min(
          height /
            (H_GAME - Math.max(MAGIC_WIDHT - width, 0) * MAGIC_HEIGHT_DELTA),
          width /
            (W_GAME - Math.max(MAGIC_WIDHT - width, 0) * MAGIC_WIDTH_DELTA),
        );
        elApp.style.transform = `scale(${Math.min(1, scale)})`;

        const size = elApp.getBoundingClientRect();
        elApp.style.top = `${scale > 1 ? 0 : 0 - size.top - TOP}px`;
      }
    },
    100,
  );

  useEffect(() => {
    const elApp = document.getElementById('app');

    const update = () => {
      if (elApp) {
        elApp.removeAttribute('style');
        debouncedResize(elApp, window.innerWidth, window.innerHeight);
      }
    };

    update();

    window.addEventListener('resize', update, false);

    return () => {
      window.removeEventListener('resize', update, false);
    };
  }, [debouncedResize]);

  useEffect(() => {
    checkAuth();
    fetchTranslations();

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false,
    );

    return () => {
      document.removeEventListener(
        'visibilityChange',
        handleVisibilityChange,
        false,
      );
    };
  }, [checkAuth, handleVisibilityChange]);

  useEffect(() => {
    if (modal?.needAuth) {
      setGameInfo({});
      checkAuth();
      setGrid({ items: [] });
      setModal(null);
    } else if (modal?.needClearAuth) {
      setGameInfo({});
      setGrid({ items: [] });
      setModal({
        title: t(Key.leftGame),
        buttons: [
          {
            label: t(Key.play),
            type: 'reload',
          },
        ],
      });
    }
  }, [modal, checkAuth, setModal, setGrid, t]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <SWrapper
        id="app"
        className={cx({
          //'hide-in-modal-view': isMobileDevice,
        })}
      >
        {gameInfo.session_token && (
          <>
            <Game
              gameInfo={gameInfo}
              isLoaded={isPreloaded}
              historyUrlRef={historyUrlRef}
            />
          </>
        )}
        <Modal />
        <BetsList />
      </SWrapper>
      {/* isMobileDevice && <LandscapeOverlay /> */}
      {!isPreloaded && <Preloader onLoad={setIsPreloaded} />}
      {states.paused && <SPause />}
    </div>
  );
};

export default App;
