import useSoundVendor from 'use-sound';
import { useRecoilValue } from 'recoil';

import { controlsAtom } from 'store';

import btnEnable from 'assets/sounds/button_enable.mp3';
import btnHover from 'assets/sounds/button_hover.mp3';
import goldReveal from 'assets/sounds/gold_reveal.mp3';
import stoneClick from 'assets/sounds/stone_click.mp3';
import stoneHover from 'assets/sounds/stone_hover.mp3';
import dynamiteExplosion from 'assets/sounds/dynamite_explosion.mp3';
import gameStarter from 'assets/sounds/game_starter.mp3';
import successfulGame from 'assets/sounds/win.mp3';
import mainTheme from 'assets/sounds/xmines_main_theme.mp3';
import characterWin2 from 'assets/sounds/xmines_nathanyell_exited.mp3';
import characterLose from 'assets/sounds/xmines_nathanyell_frustrated.mp3';
import characterWin1 from 'assets/sounds/xmines_nathanyell_happy.mp3';
import characterSleepy from 'assets/sounds/xmines_nathanyell_sleepy.mp3';

type TSoundName =
  | 'btnEnable'
  | 'btnHover'
  | 'goldReveal'
  | 'stoneClick'
  | 'stoneHover'
  | 'dynamiteExplosion'
  | 'gameStarter'
  | 'successfulGame'
  | 'mainTheme'
  | 'characterWin2'
  | 'characterLose'
  | 'characterWin1'
  | 'characterSleepy'
  | 'NOT_SOUND';

const soundMap: {
  [key in TSoundName]: string;
} = {
  btnEnable,
  btnHover,
  goldReveal,
  stoneClick,
  stoneHover,
  dynamiteExplosion,
  gameStarter,
  successfulGame,
  mainTheme,
  characterWin1,
  characterWin2,
  characterLose,
  characterSleepy,
  NOT_SOUND: '',
};

export const useSound = (
  name: TSoundName,
  options?: {
    [key: string]: string | boolean | number;
  },
) => {
  const controls = useRecoilValue(controlsAtom);

  return useSoundVendor(soundMap[name], {
    soundEnabled: controls.sound,
    volume: 0.2,
    ...options,
  });
};
