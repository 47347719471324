import axios from 'axios';

import { IParams } from './types';
import * as http from 'http';
import * as https from 'https';
import { getUrlParams } from 'shared/helpers/parseUrl';
import { setRelaxConfig } from './relaxClient';

let session_token: string = '';
let currency: string = '';

class Cheats {
  _forceWin: number | null = null;
  _forceLoss: number | null = null;

  constructor() {
    this._forceWin = null;
    this._forceLoss = null;
  }

  forceWin(value: any) {
    if (!(typeof value === 'number')) {
      console.log(`Incorrect force win value: ${value}. Should be number`);
      return;
    }
    this._forceWin = Math.max(0, value);
    this._forceLoss = null;
    console.log(
      `Force win is set to: ${this._forceWin}.\nForce loss is reset.`,
    );
  }

  forceLoss(value: any) {
    if (!(typeof value === 'number')) {
      console.log(`Incorrect force loss value: ${value}. Should be number`);
      return;
    }

    this._forceLoss = Math.max(0, value);
    this._forceWin = null;
    console.log(
      `Force loss is set to: ${this._forceLoss}.\nForce win is reset.`,
    );
  }

  getCheatValues(): any {
    if (this._forceWin != null) {
      this._forceWin--;
      console.log(
        `Sending force_win: true.\nForce wins left: ${this._forceWin}`,
      );
      if (this._forceWin === 0) {
        this._forceWin = null;
      }
      return {
        force_win: true,
      };
    }

    if (this._forceLoss != null) {
      this._forceLoss--;
      if (this._forceLoss >= 0) {
        console.log(
          `Sending force_win: true.\nBets to force_loss left: ${this._forceLoss}`,
        );
        return {
          force_win: true,
        };
      }
      this._forceLoss = null;
      console.log(`Sending force_loss: true.`);
      return {
        force_loss: true,
      };
    }

    return {};
  }
}

const cheats = new Cheats();
//@ts-ignore
window.forceWin = cheats.forceWin.bind(cheats);
//@ts-ignore
window.forceLoss = cheats.forceLoss.bind(cheats);

const agentOptions = {
  keepAlive: true,
};

export const client = axios.create({
  baseURL: 'https://curacao-backend.gamingcorpscasino.com/casino/mines',
  headers: {
    'Content-Type': 'application/json',
  },
  httpAgent: new http.Agent(agentOptions),
  httpsAgent: new https.Agent(agentOptions),
});

export const auth = async (params: IParams) => {
  try {
    const { data } = await client.post('/authenticate', params);

    session_token = data.session_token || '';
    currency = data.currency || '';

    if (currency) {
      setRelaxConfig({
        currency,
      });
    }

    return data;
  } catch (error) {}
  return {};
};

export const init = async (params: IParams) => {
  try {
    const { data } = await client.post('/init', {
      currency,
      session_token,
      ...params,
    });

    return data.status === 200 ? data : false;
  } catch (error) {}
};

export const bet = async (params: IParams) => {
  try {
    const { data } = await client.post('/bet', {
      currency,
      session_token,
      ...params,
      ...cheats.getCheatValues(),
    });

    return data;
  } catch (error) {}
};

export const cashout = async () => {
  try {
    const { data } = await client.post('/cashout', {
      currency,
      session_token,
    });

    return data;
  } catch (error) {}
};

export const terminate = async () => {
  try {
    if (!session_token) {
      return;
    }
    const { data } = await client.post('/terminate', {
      session_token,
    });
    return data;
  } catch (error) {}
};

export const getTranslations = async () => {
  try {
    const { translations_url } = getUrlParams();
    const { data } = await axios.get(translations_url);

    return data || {};
  } catch (error) {
    console.log('getTranslations', error);
  }
  return {};
};

export const getBalance = async () => {
  try {
    if (!session_token) {
      return;
    }

    const { data } = await client.post('/balance', {
      session_token,
    });
    return data;
  } catch (error) {}
  return {};
};

export const getHistory = async () => {
  try {
    if (!session_token) {
      return;
    }

    const { data } = await client.post('/history', {
      session_token,
    });

    return data;
  } catch (error) {}
  return {};
};
