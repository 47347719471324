import styled, { css } from 'styled-components';
import iconArrow from 'assets/icons/ic_arrow_right.min.svg';

export const SWrapper = styled.div`
  max-width: 520px;
  margin: 0 auto;
  padding: 0 0 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  @media (max-width: 990px) {
    padding: 0 10px 40px;
  }
`;

export const SContent = styled.div`
  margin: 10px 0 20px;
  text-align: center;
`;

export const SHeader = styled.h2`
  font-size: 18px;
  line-height: 24px;
  margin: 10px 0 20px;
  text-transform: uppercase;
`;

export const SSubHeader = styled.h3`
  margin: 10px 0 20px;
  font-weight: 500;
  color: #f43f3d;
`;

export const STypeHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 35px 0 20px;
`;

export const STypeTitle = styled.h5`
  color: rgb(40, 234, 27);
  margin: 10px 0;
  text-transform: uppercase;
`;

export const STypeCaption = styled.p`
  margin: 10px 0;
  font-size: 14px;
  line-height: 18px;
`;

export const STypeFigure = styled.figure`
  display: block;
  border-radius: 8px;
  max-width: 320px;
  margin: 10px auto;
  overflow: hidden;
`;

export const STypeImage = styled.img`
  display: block;
  margin: 0;
  width: 100%;
  min-height: 50px;
`;

export const STypeText = styled.div`
  margin: 10px 0 20px;
  text-align: left;
`;

export const STypeList = styled.ul`
  margin: 10px 0 20px;
  padding: 0;
  list-style: none;
  text-align: left;
`;

export const STypeItem = styled.li`
  position: relative;
  margin: 10px 0;
  padding: 0 0 0 30px;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    left: 0;
    top: 50%;
    border-radius: 5px;
    background: rgb(40, 234, 27);
    transform: translateY(-50%);
  }
`;

export const SSliderWrapper = styled.div`
  margin: 0 0 20px;
  position: relative;

  * {
    outline: none !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    left: 20px;
    bottom: 40px;
    z-index: 10;
    cursor: pointer;
    background: #2c2a1c;

    &:hover {
      background: #383622;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      line-height: 30px;
      font-size: 32px;
      color: rgb(40, 234, 27);
      transform: translate(-50%, -50%) rotate(180deg);
      background: url(${iconArrow}) center center no-repeat;
      background-size: auto 24px;
    }

    @media (max-width: 990px) {
      display: none;
    }
  }
  .swiper-button-next {
    right: 20px;
    left: auto;

    &:after {
      transform: translate(-50%, -50%);
    }
  }

  .swiper-pagination {
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 22;

    .swiper-pagination-bullet {
      position: relative;
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 1px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 6px;
        margin: 2px;
        border-radius: 3px;
        background: rgb(40, 234, 27, 0.2);
        transition: width 0.05s;
        transform: translateX(-3px);
      }

      &.swiper-pagination-bullet-active {
        width: 20px;

        &:after {
          width: 16px;
          background: rgb(40, 234, 27);
        }
      }
    }
  }
`;

export const SItem = styled.section`
  width: 100%;
  padding: 0 0 40px;

  @media (max-width: 990px) {
    padding: 0 20px 20px;
  }
`;

export const SItemImg = styled.img`
  display: inline-block;
  max-width: calc(100% - 30px);
  max-height: 500px;
  height: auto;
`;

export const SText = styled.div<{ withMargin: boolean }>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 0 100px;
    `};

  @media (max-width: 990px) {
    margin: 0;
  }
`;

export const SItemHeader = styled.h3`
  margin: 20px 0;
  font-size: 18px;
  font-weight: 500;
  color: rgb(40, 234, 27);
  text-transform: uppercase;
`;

export const SItemContent = styled.div`
  font-size: 14px;
  word-wrap: break-word;
  max-width: calc(100% - 30px);
  margin: 20px auto 10px;
  color: var(--iron-color);

  @media (max-width: 990px) {
    margin: 10px auto 10px;
  }
`;

export const SButton = styled.button`
  height: 42px;
  min-width: 45px;
  width: 80%;
  margin-top: 20px;
  font-weight: 500;
  font-size: 18px;
  padding: 6px 10px;
  background: var(--primary-color);
  color: var(--inverse-color);
  border: 1px solid var(--inverse-color);
  border-radius: 4px;
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  @media (min-width: 990px) and (min-aspect-ratio: 1/1) {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;
