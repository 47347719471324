import { FC, useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

import * as relaxClient from 'api/relaxClient';
import threepiClient from 'api/threepiClient';
import gameBridgeManager from 'api/gameBridge';
import { imageToBlob } from 'store/imageStore';
import imageStore from 'store/imageStore';
import { ReactComponent as BrandLogo } from 'assets/images/logo.min.svg';
import { files } from './files';
import {
  SPreloader,
  SLogo,
  SImage,
  SProgressBar,
  SProgressTrack,
} from './style';
import { TFiles } from './types';

const Preloader: FC<{ onLoad: (status: boolean) => void }> = ({ onLoad }) => {
  const portalEL = document.getElementById('portal-root');

  const getFilesLength = useCallback(
    (files: TFiles) =>
      Object.keys(files).reduce((acc, key) => acc + files[key].length, 0),
    [],
  );

  const [total] = useState<number>(() => getFilesLength(files));
  const [loadedFiles, setLoadedFiles] = useState<number>(0);

  useEffect(() => {
    Object.keys(files).forEach(key => {
      files[key].forEach(file => {
        switch (key) {
          case 'callback':
            (file as Function)(() => {
              setLoadedFiles(prev => prev + 1);
            });
            break;
          case 'image':
            const img = new Image();
            img.src = file as string;
            img.addEventListener('load', () => {
              setLoadedFiles(prev => prev + 1);
            });
            break;
          case 'imageToBlob':
            imageToBlob(file as string, result => {
              imageStore.addImage(file as string, result);
              setLoadedFiles(prev => prev + 1);
            });
            break;
          case 'fetch':
            const audio = new Audio(file as string);
            audio.onloadedmetadata = () => {
              setLoadedFiles(prev => prev + 1);
            };
            break;
          default:
            setLoadedFiles(prev => prev + 1);
        }
      });
    });
  }, [setLoadedFiles]);

  useEffect(() => {
    const elHtml = document.querySelector('html');
    elHtml?.classList.add('scroll-hidden');

    if (loadedFiles >= total) {
      onLoad(true);
      relaxClient.setProgressLoad(100);
      relaxClient.endLoad();

      threepiClient.setProgressLoad(100);
      threepiClient.endLoad();

      gameBridgeManager.onLoadEnded();
    } else {
      relaxClient.setProgressLoad((loadedFiles * 100) / total);
      threepiClient.setProgressLoad((loadedFiles * 100) / total);
    }

    return () => {
      elHtml?.classList.remove('scroll-hidden');
    };
  }, [loadedFiles, total, onLoad]);

  useEffect(() => {
    relaxClient.startLoad();
    threepiClient.startLoad();
  }, []);

  return (
    portalEL &&
    createPortal(
      <SPreloader>
        <SLogo>
          <SImage>
            <BrandLogo />
          </SImage>
          <SProgressBar>
            <SProgressTrack
              style={{
                width: `${(loadedFiles * 100) / total}%`,
              }}
            />
          </SProgressBar>
        </SLogo>
      </SPreloader>,
      portalEL,
    )
  );
};

export default Preloader;
