import styled, { css } from 'styled-components';

const fixedStyle = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const SWrapper = styled.div`
  ${fixedStyle};
  vertical-align: middle;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow-y: auto;
  user-select: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
    width: 0;
    margin-left: -5px;
    pointer-events: none;
  }
`;

export const SOverlay = styled.div`
  ${fixedStyle};
  z-index: 10;
`;

export const SModal = styled.div`
  position: relative;
  vertical-align: middle;
  display: inline-block;
  max-width: 440px;
  width: calc(100% - 40px);
  white-space: normal;
  z-index: 20;
`;

export const SInner = styled.div`
  background: var(--primary-color);
  padding: 20px 20px 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: center;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5);
`;

export const SHeader = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 28px;
  margin: 0 0 20px;
`;

export const SMessage = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: rgb(144, 144, 149);
  margin: 0 0 40px;
`;

export const SButtonWrapper = styled.div`
  display: flex;
  position: sticky;
  line-height: 1.48;
  gap: 5px;
  bottom: 0;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > button {
  }
`;
