import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    padding: 15px 20px;
  }

  @media (max-width: 520px) and (max-aspect-ratio: 1/1) {
    padding: 10px 20px;
  }

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    padding: 6px 12px;
  }

  @media (max-width: 300px) and (max-aspect-ratio: 1/1) {
    padding: 10px 12px;
  }
`;

export const SElement = styled.div<{ isRight?: boolean }>`
  ${({ isRight }) =>
    isRight &&
    css`
      text-align: right;
      margin-left: auto;

      div {
        color: var(--info-c-text);
        text-shadow: none;
      }
    `}
`;

export const SLabel = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--info-c-label);
  text-transform: uppercase;

  @media (max-width: 500px) and (max-aspect-ratio: 1/1) {
    font-size: 10px;
  }

  @media (max-width: 300px) and (max-aspect-ratio: 1/1) {
    font-size: 8px;
  }
`;

export const SValue = styled.div`
  font-size: 15px;
  margin-top: 0;
  color: var(--info-c-highlight);
  font-weight: 500;
  text-shadow: 0 0 15px rgba(255, 227, 16, 0.3);

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    margin-top: 0;
    font-size: 13px;
  }

  @media (max-width: 300px) and (max-aspect-ratio: 1/1) {
    font-size: 10px;
  }
`;
