import { atom } from 'recoil';
import { IRow } from 'shared/components/Board';

export const gridAtom = atom<{
  items: IRow[];
}>({
  key: 'gridAtom',
  default: {
    items: [],
  },
});
