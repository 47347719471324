export const imageToBlob = async (
  src: string,
  callback: (result: string) => void,
) => {
  try {
    const r = await fetch(src);
    const blob = await r.blob();
    callback(URL.createObjectURL(blob));
  } catch (e) {
    callback(src);
  }
};

const ImageStore = () => {
  const images: { [key: string]: string } = {};

  const addImage = (key: string, value: string) => {
    images[key] = value;
  };

  const removeImage = (key: string) => {
    if (images[key]) {
      delete images[key];
    }
  };

  const getImage = (key: string): string => {
    const value = images[key];
    if (value) {
      return value;
    }
    return key;
  };

  return {
    addImage,
    removeImage,
    getImage,
  };
};

const imageStore = ImageStore();
export default imageStore;
