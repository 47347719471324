import { TElement } from './types';
import { Key } from 'i18n/Key';
import { addDefaultTranslates } from 'i18n/config';

import img_about1 from 'assets/images/intro/about/img_about1@2x.min.png';
import img_about2 from 'assets/images/intro/about/img_about2@2x.min.png';
import img_about3 from 'assets/images/intro/about/img_about3@2x.min.png';
import img_about4 from 'assets/images/intro/about/img_about4@2x.min.png';
import img_about5 from 'assets/images/intro/about/img_about5@2x.min.png';
import img_about9 from 'assets/images/intro/about/img_about9@2x.min.png';
import img_about10 from 'assets/images/intro/about/img_about10@2x.min.png';
import { getUrlParams } from 'shared/helpers/parseUrl';

const urlParams = getUrlParams();

//const GAME_CODE_A = ['mine_bomb'];
//const GAME_CODE_B = ['mine_bomb_90', 'coin_miner_90'];
const GAME_CODE_B: string[] = [];

addDefaultTranslates({
  [Key.img_about1]: img_about1,
  [Key.img_about2]: img_about2,
  [Key.img_about3]: img_about3,
  [Key.img_about4]: img_about4,
  [Key.img_about5]: img_about5,
  [Key.img_about9]: img_about9,
  [Key.img_about10]: img_about10,
});

const isGameCodeValid = (codes: string[], gameCode: string): boolean => {
  return codes.includes(gameCode);
};

const gameCode: string =
  urlParams != null && urlParams.hasOwnProperty('game_code')
    ? urlParams['game_code']
    : '';

const gameCodeB = isGameCodeValid(GAME_CODE_B, gameCode);

export const aboutRTP = gameCodeB ? Key.infoP10B : Key.infoP10;

const minesMultipliers: { mines: number; multi: number[] }[] = [
  {
    mines: 1,
    multi: [
      1.01, 1.05, 1.1, 1.15, 1.21, 1.28, 1.35, 1.43, 1.52, 1.62, 1.73, 1.87,
      2.02, 2.2, 2.43, 2.69, 3.03, 3.46, 4.04, 4.85, 6.06, 8.08, 12.13, 24.33,
    ],
  },
  {
    mines: 2,
    multi: [
      1.05, 1.15, 1.26, 1.39, 1.53, 1.7, 1.9, 2.14, 2.42, 2.77, 3.2, 3.73, 4.41,
      5.29, 6.47, 8.08, 10.39, 13.86, 19.4, 29.09, 48.49, 96.98, 290.97,
    ],
  },
  {
    mines: 3,
    multi: [
      1.1, 1.26, 1.45, 1.68, 1.96, 2.3, 2.73, 3.28, 3.98, 4.9, 6.13, 7.8, 10.14,
      13.52, 18.59, 26.56, 39.84, 63.75, 106.25, 193.18, 386.36, 930.99,
    ],
  },
  {
    mines: 5,
    multi: [
      1.21, 1.5, 1.87, 2.34, 2.96, 3.77, 4.84, 6.24, 8.32, 11.4, 15.83, 22.29,
      31.85, 46.83, 69.9, 107.54, 168.02, 270.14, 476.43, 992.57,
    ],
  },
  {
    mines: 7,
    multi: [
      1.29, 1.73, 2.32, 3.13, 4.28, 5.91, 8.19, 11.45, 16.08, 22.81, 32.44,
      46.68, 76.53, 126.49, 209.42, 347.29, 583.69, 997.75,
    ],
  },
  {
    mines: 10,
    multi: [
      1.5, 2.33, 3.61, 5.6, 8.69, 13.47, 20.89, 33.15, 52.62, 83.53, 132.58,
      210.45, 334.05, 530.24, 996.69,
    ],
  },
  {
    mines: 17,
    multi: [1.76, 3.53, 7.84, 18.23, 44.46, 108.44, 293.08, 996.88],
  },
  {
    mines: 20,
    multi: [2.77, 9.24, 30.79, 102.65, 996.56],
  },
  {
    mines: 24,
    multi: [24.25],
  },
];

const minesMultipliersB: { mines: number; multi: number[] }[] = [
  {
    mines: 1,
    multi: [
      0.93, 0.97, 1.01, 1.06, 1.11, 1.17, 1.23, 1.31, 1.39, 1.48, 1.59, 1.71,
      1.85, 2.02, 2.22, 2.47, 2.77, 3.17, 3.7, 4.44, 5.55, 7.4, 11.1, 22.33,
    ],
  },
  {
    mines: 2,
    multi: [
      0.97, 1.05, 1.15, 1.27, 1.4, 1.56, 1.74, 1.96, 2.22, 2.54, 2.93, 3.42,
      4.04, 4.84, 5.92, 7.4, 9.51, 12.68, 17.76, 26.63, 44.39, 88.78, 266.38,
    ],
  },
  {
    mines: 3,
    multi: [
      1.01, 1.15, 1.33, 1.54, 1.79, 2.11, 2.5, 3.0, 3.65, 4.49, 5.61, 7.14,
      9.29, 12.38, 17.02, 24.32, 36.48, 58.36, 97.27, 176.85, 353.7, 930.79,
    ],
  },
  {
    mines: 5,
    multi: [
      1.11, 1.37, 1.71, 2.14, 2.71, 3.45, 4.43, 5.71, 7.62, 10.43, 14.49, 20.41,
      29.15, 42.87, 63.99, 98.45, 153.82, 248.1, 443.03, 984.52,
    ],
  },
  {
    mines: 7,
    multi: [
      1.18, 1.59, 2.15, 2.92, 4.0, 5.52, 7.67, 10.72, 15.1, 21.43, 30.61, 44.04,
      72.2, 119.33, 198.89, 331.48, 571.52, 993.95,
    ],
  },
  {
    mines: 10,
    multi: [
      1.38, 2.15, 3.36, 5.25, 8.21, 12.82, 20.03, 31.8, 50.48, 80.12, 127.18,
      201.87, 320.43, 508.62, 997.29,
    ],
  },
  {
    mines: 17,
    multi: [1.61, 3.23, 7.18, 16.69, 40.7, 99.27, 268.31, 993.72],
  },
  {
    mines: 20,
    multi: [2.54, 8.46, 28.19, 93.97, 939.68],
  },
  {
    mines: 24,
    multi: [22.2],
  },
];

const generatedMines = (
  gameCodeB ? minesMultipliersB : minesMultipliers
).map<TElement>(({ mines, multi }) => ({
  type: 'table',
  content: {
    title: Key.dynamiteAmount,
    title2: ` — ${mines}`,
    columns: [Key.tilesOpened, Key.betMultiplier],
    data: multi.map(item => item.toFixed(2)),
  },
}));

export const elements: TElement[] = [
  {
    type: 'header',
    content: Key.aboutHeader1,
  },
  {
    type: 'text',
    content: Key.aboutText1,
  },
  {
    type: 'text',
    content: Key.aboutText2,
  },
  {
    type: 'text',
    content: Key.aboutText3,
  },
  {
    type: 'header',
    content: Key.aboutHeader2,
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage1Title,
      src: Key.img_about1,
      caption: Key.aboutImage1Caption,
    },
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage2Title,
      src: Key.img_about2,
      caption: Key.aboutImage2Caption,
    },
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage3Title,
      src: Key.img_about3,
      caption: Key.aboutImage3Caption,
    },
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage4Title,
      src: Key.img_about4,
      caption: Key.aboutImage4Caption,
    },
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage5Title,
      src: Key.img_about5,
      caption: Key.aboutImage5Caption,
    },
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage9Title,
      src: Key.img_about9,
      caption: Key.aboutImage9Caption,
    },
  },
  {
    type: 'image',
    content: {
      title: Key.aboutImage10Title,
      src: Key.img_about10,
      caption: Key.aboutImage10Caption,
    },
  },
  {
    type: 'header',
    content: Key.aboutHeader3,
  },
  {
    type: 'header',
    content: Key.aboutImage6Title,
  },
  {
    type: 'text',
    content: Key.aboutImage6Caption,
  },
  {
    type: 'header',
    content: Key.aboutImage7Title,
  },
  {
    type: 'text',
    content: Key.aboutImage7Caption,
  },
  {
    type: 'header',
    content: Key.aboutImage8Title,
  },
  {
    type: 'text',
    content: Key.aboutImage8Caption,
  },
  {
    type: 'header',
    content: Key.aboutImage11Title,
  },
  {
    type: 'text',
    content: Key.aboutImage11Caption,
  },
  {
    type: 'header',
    content: Key.aboutHeader4,
  },
  {
    type: 'text',
    content: gameCodeB ? Key.aboutText4B : Key.aboutText4,
  },
  {
    type: 'list',
    content: [
      {
        title: gameCodeB ? Key.aboutList1TitleB : Key.aboutList1Title,
        text: gameCodeB ? Key.aboutList1TextB : Key.aboutList1Text,
      },
      {
        title: gameCodeB ? Key.aboutList2TitleB : Key.aboutList2Title,
        text: gameCodeB ? Key.aboutList2TextB : Key.aboutList2Text,
      },
    ],
  },
  {
    type: 'header',
    content: Key.aboutHeader6,
  },
  {
    type: 'text',
    content: Key.aboutImage91Caption,
  },
  {
    type: 'text',
    content: Key.aboutImage92Caption,
  },
  {
    type: 'list',
    content: [
      {
        title: Key.aboutImage931ListTitle,
        text: Key.aboutImage931ListText,
      },
      {
        title: Key.aboutImage932ListTitle,
        text: Key.aboutImage932ListText,
      },
      {
        title: Key.aboutImage933ListTitle,
        text: Key.aboutImage933ListText,
      },
    ],
  },
  {
    type: 'text',
    content: Key.aboutImage94Caption,
  },
  {
    type: 'header',
    content: Key.aboutHeader5,
  },
  {
    type: 'text',
    content: Key.aboutText5,
  },
  {
    type: 'text',
    content: Key.aboutText51,
  },
  ...generatedMines,
  {
    type: 'header',
    content: Key.aboutHeader7,
  },
  {
    type: 'text',
    content: Key.aboutHeader8,
  },
  {
    type: 'header',
    content: Key.gameVersion,
  },
];
