import styled from 'styled-components';

export const SWrapper = styled.div`
  table {
    position: relative;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    thead {
      td,
      th {
        padding: 10px 8px;
        font-size: 12px;
        color: var(--manatee-color);
        text-transform: uppercase;
        position: sticky;
        background: var(--primary-color);
        top: -10px;

        @media (max-width: 990px) {
          top: 35px;
        }
      }
    }

    tbody {
      td {
        margin: 0;

        &:first-child span {
          border-radius: 4px 0 0 4px;
        }

        &:last-child span {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
`;

export const SText = styled.span`
  line-height: 18px;
  display: block;
  padding: 6px 8px;
  color: var(--iron-color);
  background: var(--secondary-color);
  min-width: 70px;
`;
