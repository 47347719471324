import { FC } from 'react';
import { SWrapper, SElement, SLabel, SValue } from './style';
import { IInfoBoardProps } from './types';
import { useTranslation } from 'react-i18next';
import { Key } from 'i18n/Key';

const InfoBoard: FC<IInfoBoardProps> = ({
  nextTile,
  openedCount,
  count = 25,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      {nextTile && (
        <SElement>
          <SLabel>{t(Key.nextTile)}</SLabel>
          <SValue>{nextTile}</SValue>
        </SElement>
      )}

      <SElement isRight>
        <SLabel>{t(Key.nuggetsFound)}</SLabel>
        <SValue>
          {openedCount}/{count}
        </SValue>
      </SElement>
    </SWrapper>
  );
};

export default InfoBoard;
