import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { Key } from 'i18n/Key';
import Button, { Text } from 'shared/components/Button';

import { IHistoryItem, betsListState } from './store/state';
import { gameInfoAtom } from 'store';
import {
  SWrapper,
  SModal,
  SInner,
  STitle,
  SSubTitle,
  SOverlay,
  SFooter,
  SSpinner,
  SNoItems,
  SList,
  SItem,
  SSections,
  SSection,
  SField,
  SFieldKey,
  SFieldValue,
  SCollapser,
  SMore,
} from './style';
import dayjs from 'dayjs';
import Span from '../Span';
import threepiClient from 'api/threepiClient';

import { getHistory } from 'api/client';

const BetsList: FC = () => {
  const { t } = useTranslation();
  const [{ currency_hex_code }] = useRecoilState(gameInfoAtom);
  const [openCells, setOpenCells] = useState<number[]>([0]);
  const portalEL = document.getElementById('portal-root');
  const [{ isActive, isLoading, items = [] }, setBetsList] =
    useRecoilState(betsListState);

  useEffect(() => {
    if (isLoading) {
      getHistory().then(items => {
        setBetsList(p => ({
          isActive: p.isActive,
          isLoading: false,
          items: prepareItems(items),
        }));
      });
    }
  }, [isLoading, setBetsList]);

  const prepareItems = (items?: IHistoryItem[]): IHistoryItem[] | undefined => {
    if (!items) {
      return items;
    }

    const preparedItems = items.map(it => {
      let pos = 0;
      it.positions.forEach(([i1, i2]) => {
        it.grid[i1][i2] = { ...it.grid[i1][i2], _order: ++pos };
      });
      return it;
    });

    return preparedItems;
  };

  const handleClose = () => {
    threepiClient.updateBetsHistory(false);

    setBetsList({
      isActive: false,
      isLoading: false,
      items: [],
    });
  };

  const handleToggleItem = (index: number) => () => {
    setOpenCells(prev => {
      if (prev.includes(index)) {
        return prev.filter(ind => index !== ind);
      }
      return prev.concat(index);
    });
  };

  useEffect(() => {
    if (isActive) {
      setOpenCells([]);
    }
  }, [isActive]);

  return isActive && portalEL
    ? createPortal(
        <SWrapper>
          <SModal>
            <SInner>
              <STitle>Christmas Miner</STitle>
              <SSubTitle>{t(Key.betsHistory)}</SSubTitle>
              {isLoading ? (
                <SSpinner>
                  <svg viewBox="25 25 50 50">
                    <circle cx="50" cy="50" r="20" />
                  </svg>
                </SSpinner>
              ) : (
                items.length === 0 && <SNoItems>{t(Key.noItems)}</SNoItems>
              )}

              <SList>
                {items.map(
                  (
                    {
                      end_time,
                      bet_time,
                      balance_before,
                      balance_after,
                      bet_amount,
                      win_amount,
                      grid,
                    },
                    index,
                  ) => (
                    <SItem
                      key={bet_time}
                      onClick={handleToggleItem(index)}
                      data-is-open={openCells.includes(index)}
                    >
                      <SSections>
                        <SSection>
                          <SField>
                            <SFieldKey>{t(Key.balanceBefore)}</SFieldKey>
                            <SFieldValue>
                              <Span hexCode={currency_hex_code} />
                              {balance_before}
                            </SFieldValue>
                          </SField>

                          <SField>
                            <SFieldKey>{t(Key.bet)}</SFieldKey>
                            <SFieldValue>
                              <Span hexCode={currency_hex_code} />
                              {bet_amount}
                            </SFieldValue>
                          </SField>
                        </SSection>

                        <SSection>
                          <SField>
                            <SFieldKey>{t(Key.balanceAfter)}</SFieldKey>

                            <SFieldValue>
                              <Span hexCode={currency_hex_code} />
                              {balance_after}
                            </SFieldValue>
                          </SField>

                          <SField>
                            <SFieldKey>{t(Key.win)}</SFieldKey>

                            <SFieldValue>
                              <Span hexCode={currency_hex_code} />
                              {win_amount}
                            </SFieldValue>
                          </SField>
                        </SSection>

                        <SSection>
                          <SField>
                            <SFieldKey>{t(Key.duration)}</SFieldKey>
                            <SFieldValue>
                              {dayjs
                                .duration(
                                  dayjs(end_time).valueOf() -
                                    dayjs(bet_time).valueOf(),
                                )
                                .format('HH:mm:ss')}
                            </SFieldValue>
                          </SField>

                          <SField>
                            <SFieldKey>
                              {dayjs(bet_time).format('MMM DD, HH:mm')}
                            </SFieldKey>
                          </SField>
                        </SSection>
                      </SSections>

                      {openCells.includes(index) ? (
                        <SCollapser>
                          {grid.map((arr, index) => (
                            <ul key={index}>
                              {arr.map(
                                (
                                  { id, name, type, revealed, bonus, _order },
                                  ind,
                                ) => (
                                  <li
                                    key={`${id}${ind}`}
                                    title={name}
                                    data-cell-type={type}
                                    data-is-open={!revealed}
                                    data-is-bonus={!!bonus}
                                  >
                                    <span>{_order}</span>
                                  </li>
                                ),
                              )}
                            </ul>
                          ))}
                        </SCollapser>
                      ) : (
                        <SMore>{t(Key.showDetails)}</SMore>
                      )}
                    </SItem>
                  ),
                )}
              </SList>

              <SFooter>
                <Button
                  onClick={handleClose}
                  style={{ maxWidth: 180 }}
                  mode="warning"
                >
                  <Text>{t(Key.close)}</Text>
                </Button>
              </SFooter>
            </SInner>
          </SModal>
          <SOverlay />
        </SWrapper>,
        portalEL,
      )
    : null;
};

export default BetsList;
