import styled from 'styled-components';

export const StyledInformer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    span {
      text-shadow: 0px 0px 2px black;
    }
  }
`;
