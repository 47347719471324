import { IParams } from 'api/types';

const availParams = [
  'game_code',
  'platform',
  'pre_authentication_token',
  'casino_token',
  'customer',
  'currency',
  'language',
  'player_uid',
  'play_for_fun',
  'reality_check_time',
  'max_bet',
  'min_bet',
  'translations_url',
  'show_time',
  'staging',
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exampleQueryParams =
  '?game_code=gold_mine&platform=desktop&play_for_fun=false&player_uid=ef209437-0674-4d9d-8456-f2653cc356fd&casino_token=calayroche&currency=EUR&language=sv&ext_params[gamesessionid]={operator_id}_IOJEWE3JFF0&ext_params[accountid]=1086&ext_params[lobbyurl]=https://{home_url}&ext_params[historyurl]=https://{historyUrl}&ext_params[license]=UK';

export const QUERY_MAX_BET = 'max_bet';
export const QUERY_MIN_BET = 'min_bet';

export const getUrlParams = () => {
  const href = decodeURI(window.location.href);
  const { searchParams } = new URL(href);
  const params: IParams = {};

  availParams.forEach(key => {
    const val = searchParams.get(key);
    if (val) {
      params[key] = val;
    }
  });

  const ext_params = parseExtParams();

  if (ext_params) {
    params['ext_params'] = ext_params;
  }

  return params;
};

export const getLanguageFromUrl = () => {
  const href = decodeURI(window.location.href);
  const { searchParams } = new URL(href);
  return searchParams.get('language') || 'en';
};

export const parseExtParams = () => {
  let ext_params: { [key: string]: string } = {};
  let href = decodeURI(window.location.href);
  let searchParams = new URL(href).searchParams; // new URLSearchParams(href);
  for (let pair of searchParams.entries()) {
    if (pair[0].indexOf('ext_params') !== -1) {
      let field = pair[0].substring(
        pair[0].lastIndexOf('[') + 1,
        pair[0].lastIndexOf(']'),
      );
      ext_params[field] = pair[1];
    }
  }
  return ext_params;
};
