import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import { gameInfoAtom } from 'store';
import { createTranslation } from 'shared/helpers/translationUtils';

import { slides } from '../introSlides';

import { SItem, SItemContent, SItemImg, SSliderWrapper, SText } from './style';
import { SItemHeader2 } from '../style';
import { useRecoilValue } from 'recoil';
import { introState } from 'store';

const Slider: FC<{ showFlag?: boolean }> = ({ showFlag }) => {
  const { t } = useTranslation();
  const introStatus = useRecoilValue(introState);
  const gameInfo = useRecoilValue(gameInfoAtom);

  return (
    <SSliderWrapper>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        navigation={!!showFlag}
        pagination={{ clickable: true }}
        initialSlide={
          typeof introStatus?.sliderIndex === 'number'
            ? introStatus.sliderIndex
            : 0
        }
        loop
      >
        {slides.map(({ id, img, header2, content }) => (
          <SwiperSlide key={id}>
            <SItem>
              <SItemImg
                src={createTranslation(img, t, gameInfo?.country)}
                alt={createTranslation(header2, t, gameInfo?.country)}
              />
              <SText withMargin={!!showFlag}>
                <SItemHeader2>
                  {createTranslation(header2, t, gameInfo?.country)}
                </SItemHeader2>
                <SItemContent>
                  {createTranslation(content, t, gameInfo?.country)}
                </SItemContent>
              </SText>
            </SItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </SSliderWrapper>
  );
};

export default Slider;
