import styled, { css } from 'styled-components';

const decorativeStyle = css`
  height: 42px;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    margin-bottom: 6px;
  }
`;

export const StyledButton = styled.button`
  padding: 6px 10px;
  background: var(--btn-bg-active);
  border: none;
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  min-width: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  user-select: none;

  &:disabled {
    cursor: default;
    background: var(--btn-bg-disabled);
  }

  &[data-mode='danger'] {
    min-width: 120px;
    color: var(--btn-c-text);
    background: var(--btn-bg-cashout-active);
    ${decorativeStyle}

    &:hover {
      background: var(--btn-bg-cashout-hover);
    }

    &:disabled {
      background: var(--btn-bg-cashout-disabled);
    }
  }

  &[data-mode='warning'] {
    min-width: 120px;
    background: var(--btn-bg-play-active);
    color: var(--btn-c-text);

    & > span {
      color: var(--btn-c-text);
    }
    ${decorativeStyle}

    &:hover {
      background: var(--btn-bg-play-hover);
    }

    &:disabled {
      background: var(--btn-bg-play-disabled);
    }
  }

  &[data-mode='count-down'] {
    min-width: 120px;
    flex-direction: row;
    background: rgba(255, 227, 16, 0.2);
    color: var(--info-c-highlight);
    & > span {
      margin-right: 6px;
      min-width: 53px;
      text-align: right;
    }

    & > svg {
      max-width: 20px;
      min-height: 20px;
    }
    ${decorativeStyle}
  }
`;

export const StyledLabel = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: var(--btn-c-label);
  text-transform: uppercase;
  margin-bottom: 4px;
  position: relative;
  z-index: 2;

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    margin-bottom: 0;
  }
`;

export const StyledText = styled.span`
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: var(--btn-c-text);
  text-transform: uppercase;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    span {
      font-size: 14px;
    }
  }
`;
