import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const getWindowSize = () => {
    if (typeof window !== 'undefined') {
      return {
        width: window?.innerWidth,
        height: window?.innerHeight,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  };

  const [screen, setScreen] = useState<{
    width: number;
    height: number;
  }>(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setScreen(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screen;
};
