import { SReactSpinePlayer } from './style';
import * as React from 'react';
import mSpinePlayerWrapper from 'spine-player/spine-player-wrapper';

import { useEffect } from 'react';

const ReactSpinePlayer = () => {
  const id = 'character';

  useEffect(() => {
    const playerWrapper = mSpinePlayerWrapper;
    const handleTabClosing = () => {
      playerWrapper.detach();
    };
    playerWrapper.attach(id);
    window.addEventListener('beforeunload', handleTabClosing);
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('beforeunload', handleTabClosing);
      window.removeEventListener('unload', handleTabClosing);
      playerWrapper.detach();
    };
  }, []);

  return <SReactSpinePlayer id={id} />;
};

export default ReactSpinePlayer;
