export enum Key {
  play = 'play',
  selectBetAmount = 'selectBetAmount',
  dynamiteAmount = 'dynamiteAmount',
  mines = 'mines',
  balance = 'balance',
  reload = 'reload',
  cashOut = 'cashOut',
  nextTile = 'nextTile',
  nuggetsFound = 'found',
  keepPlaying = 'keepPlaying',
  quitTheGame = 'quitTheGame',
  leftGame = 'leftGame',
  realityCheck1 = 'realityCheck1',
  realityCheck2 = 'realityCheck2',
  realityCheck3 = 'realityCheck3',
  realityCheck4 = 'realityCheck4',
  nextGameTimerT = 'nextGameTimerT',
  infoGotIt = 'infoGotIt',
  doNotShow = 'doNotShow',
  rotatePhone = 'rotatePhone',
  maxWin = 'maxWin',
  continue = 'continue',
  betAmount = 'betAmount',
  freeBets = 'freeBets',
  totalWin = 'totalWin',
  freeBetsStart = 'freeBetsStart',
  freeBetsEnd = 'freeBetsEnd',
  connectionError = 'connectionError',
  timeout = 'timeout',
  timeoutDesc = 'timeoutDesc',
  infoP10 = 'infoP10',
  infoP10B = 'infoP10B',
  infoP21 = 'infoP21',
  infoP22 = 'infoP22',
  infoP31 = 'infoP31',
  infoP32 = 'infoP32',
  infoP41 = 'infoP41',
  infoP42 = 'infoP42',
  infoP61 = 'infoP61',
  infoP62 = 'infoP62',
  tutorial = 'tutorial',
  about = 'about',
  aboutTitle = 'aboutTitle',
  aboutText1 = 'aboutText1',
  aboutText2 = 'aboutText2',
  aboutText3 = 'aboutText3',
  aboutText4 = 'aboutText4',
  aboutText4B = 'aboutText4B',
  aboutText5 = 'aboutText5',
  aboutText51 = 'aboutText51',
  aboutHeader1 = 'aboutHeader1',
  aboutHeader2 = 'aboutHeader2',
  aboutHeader3 = 'aboutHeader3',
  aboutHeader4 = 'aboutHeader4',
  aboutHeader5 = 'aboutHeader5',
  aboutHeader6 = 'aboutHeader6',
  aboutHeader7 = 'aboutHeader7',
  aboutHeader8 = 'aboutHeader8',
  aboutImage1Title = 'aboutImage1Title',
  aboutImage1Caption = 'aboutImage1Caption',
  aboutImage2Title = 'aboutImage2Title',
  aboutImage2Caption = 'aboutImage2Caption',
  aboutImage3Title = 'aboutImage3Title',
  aboutImage3Caption = 'aboutImage3Caption',
  aboutImage4Title = 'aboutImage4Title',
  aboutImage4Caption = 'aboutImage4Caption',
  aboutImage5Title = 'aboutImage5Title',
  aboutImage5Caption = 'aboutImage5Caption',
  aboutImage6Title = 'aboutImage6Title',
  aboutImage6Caption = 'aboutImage6Caption',
  aboutImage7Title = 'aboutImage7Title',
  aboutImage7Caption = 'aboutImage7Caption',
  aboutImage8Title = 'aboutImage8Title',
  aboutImage8Caption = 'aboutImage8Caption',
  aboutImage9Title = 'aboutImage9Title',
  aboutImage9Caption = 'aboutImage9Caption',
  aboutImage91Caption = 'aboutImage91Caption',
  aboutImage931ListTitle = 'aboutImage931ListTitle',
  aboutImage931ListText = 'aboutImage931ListText',
  aboutImage932ListTitle = 'aboutImage932ListTitle',
  aboutImage932ListText = 'aboutImage932ListText',
  aboutImage933ListTitle = 'aboutImage933ListTitle',
  aboutImage933ListText = 'aboutImage933ListText',
  aboutImage92Caption = 'aboutImage92Caption',
  aboutImage94Caption = 'aboutImage94Caption',
  aboutImage10Title = 'aboutImage10Title',
  aboutImage10Caption = 'aboutImage10Caption',
  aboutImage11Title = 'aboutImage11Title',
  aboutImage11Caption = 'aboutImage11Caption',
  aboutList1Title = 'aboutList1Title',
  aboutList1TitleB = 'aboutList1TitleB',
  aboutList1Text = 'aboutList1Text',
  aboutList1TextB = 'aboutList1TextB',
  aboutList2Title = 'aboutList2Title',
  aboutList2TitleB = 'aboutList2TitleB',
  aboutList2Text = 'aboutList2Text',
  aboutList2TextB = 'aboutList2TextB',
  tilesOpened = 'tilesOpened',
  betMultiplier = 'betMultiplier',
  bonus = 'bonus',
  gameVersion = 'gameVersion',
  backToLobby = 'backToLobby',
  img_about1 = 'img_about1',
  img_about2 = 'img_about2',
  img_about3 = 'img_about3',
  img_about4 = 'img_about4',
  img_about5 = 'img_about5',
  img_about9 = 'img_about9',
  img_about10 = 'img_about10',
  img_info_start = 'img_info_start',
  img_info_cashout = 'img_info_cashout',
  img_info_mine = 'img_info_mine',
  img_info_bonus = 'img_info_bonus',
  betsHistory = 'betsHistory',
  noItems = 'noItems',
  duration = 'duration',
  balanceAfter = 'balanceAfter',
  balanceBefore = 'balanceBefore',
  win = 'win',
  bet = 'bet',
  close = 'close',
  showDetails = 'showDetails',
}
