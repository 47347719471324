import { useEffect, FC } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState } from 'recoil';
import { terminate } from 'api/client';

import { IModalButton, modalState } from 'store';
import Button, { Text } from 'shared/components/Button';

import {
  SWrapper,
  SOverlay,
  SModal,
  SInner,
  SHeader,
  SMessage,
  SButtonWrapper,
} from './style';
import gameBridgeManager from 'api/gameBridge';

const Modal: FC = () => {
  const portalEL = document.getElementById('portal-root');
  const [modal, setModal] = useRecoilState(modalState);

  const reload = (action: IModalButton['action']) => {
    if (action) action();
    setModal({
      needAuth: true,
    });
  };

  const handleQuit = () => {
    setModal({
      needClearAuth: true,
    });
  };

  const closeModal = () => {
    setModal(null);
    (modal?.buttons || []).forEach(({ type, action }) => {
      if (type === 'close' && action) action();
    });
  };

  const terminateSession = () => {
    terminate();
  };

  const handleCallback = (
    type: IModalButton['type'],
    action: IModalButton['action'],
  ) => {
    switch (type) {
      case 'reload':
        terminateSession();
        gameBridgeManager.reload();
        reload(action);
        break;
      case 'close':
      case 'continue':
        closeModal();
        break;
      case 'quit':
        terminateSession();
        if (action && action()) {
          break;
        }
        handleQuit();
        break;
      case 'lobby':
        if (action) action();
        terminateSession();
        handleQuit();
        break;
      case 'history':
        if (action) action();
        break;
      default:
        closeModal();
    }
  };

  useEffect(() => {
    const elHtml = document.querySelector('html');

    if (elHtml && modal) elHtml.classList.add('modal-scroll-hidden');

    return () => {
      if (elHtml) elHtml.classList.remove('modal-scroll-hidden');
    };
  }, [modal]);

  return modal && portalEL
    ? createPortal(
        <SWrapper>
          <SModal>
            <SInner>
              <SHeader>{modal.title}</SHeader>
              <SMessage>{modal.message}</SMessage>
              <SButtonWrapper>
                {(modal.buttons || []).map(({ type, label, action }) => (
                  <Button
                    key={type}
                    mode="warning"
                    style={
                      type === 'quit'
                        ? {
                            background: 'var(--btn-bg-cashout-active)',
                          }
                        : {}
                    }
                    onClick={() => handleCallback(type, action)}
                  >
                    <Text>{label}</Text>
                  </Button>
                ))}
              </SButtonWrapper>
            </SInner>
          </SModal>
          <SOverlay />
        </SWrapper>,
        portalEL,
      )
    : null;
};

export default Modal;
