import { atom } from 'recoil';

export const spentTimeAtom = atom<{
  startTime: number;
  bet: number;
  won: number;
}>({
  key: 'spentTime',
  default: {
    startTime: 0,
    bet: 0,
    won: 0,
  },
});

export const errorsAtom = atom<{
  items: string[];
}>({
  key: 'errors',
  default: {
    items: [],
  },
});

export const gameInfoAtom = atom<{ [key: string]: any }>({
  key: 'info',
  default: {
    currency_hex_code: '',
  },
});
