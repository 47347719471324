import { atom } from 'recoil';

export interface IHistoryItem {
  bet_time: string;
  end_time: string;
  bet_amount: number;
  win_amount: number;
  balance_before: number;
  balance_after: number;
  multiplier: number;
  iteration_number: number;
  grid: {
    id: number;
    name: string;
    type: 'regular' | 'loss';
    revealed?: boolean;
    bonus?: boolean;
    _order?: number;
  }[][];
  mines_number: number;
  positions: number[][];
}

export interface IBetsListState {
  isActive?: boolean;
  isLoading?: boolean;
  items?: IHistoryItem[];
}

export const betsListState = atom<IBetsListState>({
  key: 'betsList',
  default: {
    isActive: false,
    isLoading: false,
    items: [],
  },
});
