import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { exitLobby } from 'shared/helpers/handleExit';
import * as relax from 'api/relaxClient';
import threepiClient from 'api/threepiClient';
import { Key } from 'i18n/Key';
import imgGameLogo from 'assets/images/game_logo.min.png';

import {
  playStatusAtom,
  gridAtom,
  spentTimeAtom,
  errorsAtom,
  processingAtom,
  introState,
  modalState,
  controlsAtom,
  statesAtom,
} from 'store';
import { bet, cashout, getBalance, init } from 'api/client';
import mPlayerWrapper, { Animation } from 'spine-player/spine-player-wrapper';
import { useSound } from 'shared/hooks/useSound';
import { PlayStatus, GETTING_BALANCE_DELAY } from 'shared/constants/game';
import Toasts, { IToastProps } from 'shared/components/Toasts';
import Board from 'shared/components/Board';
import Span from 'shared/components/Span';
import Button, { Text as ButtonText } from 'shared/components/Button';
import Controller from 'shared/components/Controller';
import Header from 'shared/components/Header';
import { IRow } from 'shared/components/Board';
import PlayingTimer from 'shared/components/PlayingTimer';
import { IntroBar } from 'shared/components/Intro';
import Freebets from 'shared/components/Freebets';
import BonusButton from 'shared/components/BonusButton';
import { getRandom } from 'shared/helpers/random';
import SessionTimer from 'shared/components/SessionTimer';
import { getUrlParams } from 'shared/helpers/parseUrl';
import { useInterval } from 'shared/hooks/useInterval';

import {
  SBox,
  SContent,
  SSidebar,
  SLogo,
  SWrapper,
  SWinInfo,
  SDownTimerText,
  SLobbyLink,
} from './style';
import Informer from './components/Informer';
import { GameStatuses, TGameStatuses, IGameProps } from './types';
import { betsListState } from 'shared/components/BetsList';
import gameBridgeManager, { receiveMessageTypes } from 'api/gameBridge';

const Game: FC<IGameProps> = ({ gameInfo, isLoaded, historyUrlRef }) => {
  const { show_time } = getUrlParams();

  const { t } = useTranslation();

  const [controlsOption, setControlsOption] = useRecoilState(controlsAtom);
  const setBetsList = useSetRecoilState(betsListState);

  const [introStatus, setIntroStatus] = useRecoilState(introState);
  const [playGameStarter] = useSound('gameStarter', { volume: 0.3 });
  const [playSuccessfulGame] = useSound('successfulGame', { volume: 0.2 });
  const [characterLose, characterLoseE] = useSound('characterLose', {
    volume: 0.3,
    interrupt: true,
  });
  const [characterWin1] = useSound('characterWin1', {
    volume: 0.4,
    interrupt: true,
  });
  const [characterWin2] = useSound('characterWin2', {
    volume: 0.3,
    interrupt: true,
  });

  const [playCharacterSleepy, characterSleepyE] = useSound('characterSleepy', {
    volume: controlsOption.sound && !controlsOption.isPausedMainTheme ? 0.3 : 0,
    interrupt: true,
    soundEnabled: controlsOption.sound && !controlsOption.isPausedMainTheme,
  });

  const [playMainTheme, { stop: stopMainTheme }] = useSound('mainTheme', {
    loop: true,
    volume: 0.2,
    interrupt: true,
    soundEnabled: controlsOption.sound && !controlsOption.isPausedMainTheme,
  });

  const setModal = useSetRecoilState(modalState);
  const [roundBalance, setRoundBalance] = useState<{
    value: number;
    type: IToastProps['type'];
  }>({ value: 0, type: 'start' });
  const [info] = useState(gameInfo);
  const [freebets, setFreebets] = useState(gameInfo.freebets);
  const [isProcessing, setIsProcessing] = useRecoilState(processingAtom);
  const setPlayStatus = useSetRecoilState(playStatusAtom);
  const [{ items }, setGrid] = useRecoilState(gridAtom);
  const setSpentTime = useSetRecoilState(spentTimeAtom);
  const setErrors = useSetRecoilState(errorsAtom);
  const setStates = useSetRecoilState(statesAtom);

  const [gameStatus, setGameStatus] = useState<TGameStatuses>(
    GameStatuses.INIT,
  );
  const [isBetting, setIsbetting] = useState<boolean>(false);
  const [iterationCount, setIterationCount] = useState<number>(0);
  const [haveBonus, setHaveBonus] = useState(
    !!gameInfo?.previous_session?.next_tile_bonus,
  );
  const [possibleWin, setPossibleWin] = useState<number>(0);
  const [nextPossibleWin, setNextPossibleWin] = useState<number>(0);
  const [countDownTimer] = useState<number>(gameInfo?.min_interval_bet || 0);
  const [countDownStartTime, setCountDownStartTIme] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [controller, setController] = useState<{
    amount?: number;
    mines?: number;
  }>({
    amount: undefined,
    mines: undefined,
  });

  const playWinAnimation = () => {
    playSuccessfulGame();
    if (Math.random() > 0.5) {
      mPlayerWrapper.playAnimation(Animation.WIN);
      characterWin1();
    } else {
      mPlayerWrapper.playAnimation(Animation.WIN_2);
      characterWin2();
    }
    characterLoseE.stop();
    characterSleepyE.stop();
  };

  const changeStatus = (status: TGameStatuses) => {
    setGameStatus(status);
  };

  const handleCashout = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    const data = await cashout();
    setHaveBonus(false);

    setIsProcessing(false);
    setFreebets(data?.freebets);

    relax.updateBalance(data?.balance || 0);
    threepiClient.updateBalance(
      data?.balance || 0,
      freebets?.coin_value || controller.amount,
      'end',
    );

    if (data?.freebets && !data?.freebets?.is_active) {
      setModal({
        title: `${t(Key.freeBetsEnd)}: ${data?.freebets?.total_win}`,
        buttons: [
          {
            label: t(Key.infoGotIt),
            type: 'close',
          },
        ],
      });
    }

    if (!data || data.status > 200) return;
    setBalance(data.balance || 0);
    setIterationCount(0);
    setGrid({
      items: mergeItems({
        newItems: data.grid,
        oldItems: items,
      }),
    });
    changeStatus(GameStatuses.END);
    playWinAnimation();
    playSuccessfulGame();
    setSpentTime(prev => ({
      ...prev,
      won: (data?.win_amount || 0) + prev.won,
    }));

    relax.updateWin(data?.win_amount || 0);
    relax.endGame(data?.balance, data?.win_amount || 0);
    threepiClient.updateWin(data?.win_amount || 0);
    threepiClient.endGame();

    gameBridgeManager.gameEnd();

    setRoundBalance(() => ({
      value: data?.win_amount || 0,
      type: 'win',
    }));
  };

  const handleController = useCallback((name: string, value: number) => {
    if (name === 'amount') {
      relax.updateBet(value);
      threepiClient.updateBet(value);
    }

    setController(prev => ({
      ...prev,
      [name]: value,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playGame = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    const data = await init({
      ...controller,
      amount: freebets?.coin_value || controller.amount,
    });

    setHaveBonus(!!data?.next_tile_bonus);
    setIsProcessing(false);
    setFreebets(data?.freebets);

    setPlayStatus({
      status: PlayStatus.PLAYING,
    });

    // Start playing game
    if (data) {
      relax.updateBalance(data?.balance || 0);
      relax.startGame(data.balance);

      threepiClient.updateBalance(
        data?.balance || 0,
        freebets?.coin_value || controller.amount,
        'start',
      );
      threepiClient.startGame();

      gameBridgeManager.gameStart();

      // start waiting counter on button
      if (countDownTimer > 0) setCountDownStartTIme(dayjs().valueOf());

      if (!data?.freebets) {
        setRoundBalance({
          value: controller.amount || 0,
          type: 'start',
        });
      }

      setSpentTime(prev => ({
        ...prev,
        bet: prev.bet + (controller?.amount || 0),
      }));

      playGameStarter();

      changeStatus(GameStatuses.PLAY);
      setBalance(data.balance || 0);
      setPossibleWin(0);
      setNextPossibleWin(data.next_possible_win || 0);
      setGrid({
        items: mergeItems({
          newItems: data.grid,
          oldItems: [],
        }),
      });
    }
  };

  const handleBet = async (coords: number[]) => {
    mPlayerWrapper.resetIdleCount();

    setIsbetting(true);
    const data = await bet({
      position: coords,
    });
    relax.updateBalance(data?.balance || 0);

    setIsbetting(false);

    if (!data || ![0, 200].includes(data.status)) {
      setErrors(prev => ({ ...prev, items: [...prev.items, 'BET_ERROR'] }));

      return false;
    }

    if (data.symbol_found?.type === 'loss') {
      relax.endGame(data?.balance, 0);

      threepiClient.updateBalance(
        data?.balance || 0,
        freebets?.coin_value || controller.amount,
        'end',
      );
      threepiClient.endGame();

      gameBridgeManager.gameEnd();

      setHaveBonus(false);

      setTimeout(() => {
        mPlayerWrapper.playAnimation(Animation.LOSE);
        characterLose();
        characterSleepyE.stop();
      }, 0);

      setRoundBalance({
        value: 0,
        type: 'win',
      });
      setPlayStatus({
        status: PlayStatus.LOSS,
      });
    }

    if (data?.freebets && !data?.freebets?.is_active) {
      setModal({
        title: `${t(Key.freeBetsEnd)}: ${data?.freebets?.total_win}`,
        buttons: [
          {
            label: t(Key.infoGotIt),
            type: 'close',
          },
        ],
      });
    }

    const {
      grid,
      position: [rInd, cInd],
      closed,
      balance,
      next_tile_bonus,
    } = data;

    if (closed) {
      setHaveBonus(false);
      changeStatus(GameStatuses.END);
      setIterationCount(0);
      setPossibleWin(0);
      setFreebets(data?.freebets);

      // Cashout
      if (data.symbol_found?.type === 'regular') {
        relax.updateWin(data?.possible_win || 0);
        relax.endGame(data?.balance, data?.possible_win || 0);

        threepiClient.updateWin(data?.possible_win || 0);
        threepiClient.endGame();

        gameBridgeManager.gameEnd();

        setRoundBalance({
          value: data?.possible_win || 0,
          type: 'win',
        });
        playWinAnimation();
        playSuccessfulGame();
        setSpentTime(prev => ({
          ...prev,
          won: (data?.possible_win || 0) + prev.won,
        }));
      }
    } else {
      setHaveBonus(!!next_tile_bonus);
      setIterationCount(data.iteration_number);
      setPossibleWin(data.possible_win);
    }

    setNextPossibleWin(data.next_possible_win || 0);

    setBalance(balance);
    setGrid({
      items: mergeItems({
        newItems: grid,
        oldItems: items,
        rInd: rInd,
        cInd: cInd,
        multiplier: data.multiplier,
      }),
    });
  };

  const mergeItems = useCallback(
    ({
      newItems,
      oldItems,
      rInd = -1,
      cInd = -1,
      multiplier = -1,
      isRestored = false,
    }: {
      newItems: IRow[];
      oldItems: IRow[];
      rInd?: number;
      cInd?: number;
      multiplier?: number;
      isRestored?: boolean;
    }) => {
      return newItems.map((row, rowIndex) =>
        row.map((col, colIndex) => {
          const { extra = {}, ...oldCol } = oldItems[rowIndex]
            ? oldItems[rowIndex][colIndex]
            : {};
          return rowIndex === rInd && colIndex === cInd
            ? {
                ...oldCol,
                ...col,
                multiplier: multiplier,
                isSelected: true,
                isLast: true,
                extra: {
                  prize: parseInt(getRandom(1, 4).toString(), 10),
                },
              }
            : {
                isSelected: col.type === 'regular' ? isRestored : false,
                ...oldCol,
                multiplier: -1,
                ...col,
                isLast: false,
                extra: {
                  prize:
                    extra?.prize || parseInt(getRandom(1, 4).toString(), 10),
                },
              };
        }),
      );
    },
    [],
  );

  useEffect(() => {
    // initial values
    setBalance(info.balance);

    if (info?.previous_session) {
      changeStatus(GameStatuses.PLAY);
      setPossibleWin(info.previous_session?.possible_win);
      setNextPossibleWin(info.previous_session?.next_possible_win);
      setGrid({
        items: mergeItems({
          newItems: info.previous_session?.mine_grid,
          oldItems: [],
          isRestored: true,
        }),
      });
      setController({
        amount: info.previous_session?.bet_amount || info?.bet_value_list[0],
        mines: info.previous_session?.mines_number,
      });

      setSpentTime(prev => ({
        ...prev,
        bet: info.previous_session?.bet_amount || 0,
      }));

      setIterationCount(info.previous_session?.iteration_number || 0);
    } else {
      setController(prev => ({
        ...prev,
        amount: info?.bet_value_list.includes(info?.default_bet_value)
          ? info.default_bet_value
          : info?.bet_value_list[0],
        mines: 1,
      }));
    }

    if (info?.freebets) {
      setModal({
        title: `${t(Key.freeBetsStart)}: ${info?.freebets.amount_left}`,
        buttons: [
          {
            label: t(Key.infoGotIt),
            type: 'close',
          },
        ],
      });
    }
  }, [info, setGrid, mergeItems, setSpentTime, setModal, t]);

  useEffect(() => {
    if (isLoaded) playMainTheme();
    else stopMainTheme();

    return () => {
      stopMainTheme();
      setIsProcessing(false);
    };
  }, [isLoaded, stopMainTheme, playMainTheme, setIsProcessing]);

  useEffect(() => {
    const listener = {
      onReady() {},

      onAnimationStart(animation: Animation) {
        if (animation === Animation.YAWN) {
          playCharacterSleepy();
        }
      },
    };

    mPlayerWrapper.subscribeListener(listener);

    return () => {
      mPlayerWrapper.unsubscribeListener(listener);
    };
  }, [playCharacterSleepy]);

  useEffect(() => {
    startGameCallbackRef.current = () => {
      if (gameStatus !== GameStatuses.PLAY && countDownStartTime < 1) {
        playGame();
      }
    };
  }, [gameStatus, countDownStartTime, controller, freebets, isProcessing]);

  const startGameCallbackRef = useRef(() => {});

  useEffect(() => {
    relax.listenEvents(on => {
      on.updateSettings(newSetting => {
        if (newSetting?.sounds !== undefined) {
          setControlsOption(prev => ({
            ...prev,
            sound: newSetting?.sounds,
          }));
        }
      });

      on.refreshBalance(() => {
        getBalance().then(({ balance: bal }) => {
          if (bal && bal > -1) {
            relax.updateBalance(bal);
            setBalance(bal);
          }
        });
      });

      on.toggleGameHelp(() => {
        setIntroStatus({
          tab: 'tutorial',
        });
      });

      on.togglePaytable(() => {
        setIntroStatus({
          tab: 'about',
          scrollTo: 'data-bet-table',
        });
      });
    });

    const handleSound = (state: boolean) =>
      setControlsOption(prev => ({
        ...prev,
        sound: state,
      }));

    const handleGameHelp = (state: boolean) =>
      setIntroStatus(
        state
          ? {
              tab: 'tutorial',
            }
          : null,
      );

    const handlePaytable = (state: boolean) =>
      setIntroStatus(
        state
          ? {
              tab: 'about',
              scrollTo: 'data-bet-table',
            }
          : null,
      );

    const handleBetsHistory = (state: boolean) => {
      setBetsList({
        isActive: state,
        isLoading: state,
      });
    };

    const handlePause = (state: boolean) => {
      setStates({ paused: state });
    };

    const handleSyncBalance = (state: boolean) => {
      getBalance().then(({ balance: bal }) => {
        if (bal && bal > -1) {
          threepiClient.updateBalance(
            bal,
            freebets?.coin_value || controller.amount,
          );
          setBalance(bal);
        }
      });
    };

    const handleUpdateBalance = (_b: boolean, value: number) => {
      setBalance(value);
    };

    const gameBridgeListener = async (message: any) => {
      switch (message.event ? message.event.toLowerCase() : undefined) {
        case receiveMessageTypes.RMT_REFRESH_BALANCE.toLowerCase():
          setBalance(message.balance || 0);
          break;
        case receiveMessageTypes.RMT_SPIN.toLowerCase():
          startGameCallbackRef.current();
          break;
        case receiveMessageTypes.RMT_SHOW_PAY_TABLE.toLowerCase():
          setIntroStatus(prev =>
            prev
              ? null
              : {
                  tab: 'about',
                  scrollTo: 'data-bet-table',
                },
          );
          break;
      }
    };

    threepiClient.on('audio', handleSound);
    threepiClient.on('help', handleGameHelp);
    threepiClient.on('paytable', handlePaytable);
    threepiClient.on('history', handleBetsHistory);
    threepiClient.on('pause', handlePause);
    threepiClient.on('sync-balance', handleSyncBalance);
    threepiClient.on('update-balance', handleUpdateBalance);

    gameBridgeManager.subscribe(gameBridgeListener);

    return () => {
      threepiClient.off('audio', handleSound);
      threepiClient.off('help', handleGameHelp);
      threepiClient.off('paytable', handlePaytable);
      threepiClient.off('history', handleBetsHistory);
      threepiClient.off('pause', handlePause);
      threepiClient.off('sync-balance', handleSyncBalance);
      threepiClient.off('update-balance', handleUpdateBalance);

      gameBridgeManager.unsubscribe(gameBridgeListener);
    };
  }, []);

  useInterval(() => {
    getBalance().then(({ balance: bal }) => {
      if (bal && bal > -1) {
        relax.updateBalance(bal);
        threepiClient.updateBalance(
          bal,
          freebets?.coin_value || controller.amount,
        );
        setBalance(bal);
      }
    });
  }, GETTING_BALANCE_DELAY);

  return (
    <SWrapper>
      <SContent>
        <Header>
          <Toasts
            value={roundBalance.value}
            type={roundBalance.type}
            currency={info?.currency_hex_code}
          />

          <SBox data-box="informer-header">
            <Informer
              balance={balance}
              currency={info.currency_hex_code || ''}
            />
          </SBox>

          <BonusButton disabled={!haveBonus} />
        </Header>

        <Board
          isGamePlaying={gameStatus === GameStatuses.PLAY}
          data={items}
          disabled={gameStatus !== GameStatuses.PLAY || isBetting}
          onBet={handleBet}
          nextTile={
            nextPossibleWin > 0 && (
              <>
                <Span hexCode={info.currency_hex_code} />
                <span>{nextPossibleWin}</span>
              </>
            )
          }
          openedCount={iterationCount}
          mines={controller.mines}
        />
      </SContent>

      <SSidebar>
        <SLogo src={imgGameLogo} />
        {introStatus && <IntroBar onChange={setIntroStatus} />}

        <Toasts
          value={roundBalance.value}
          type={roundBalance.type}
          currency={info?.currency_hex_code}
        />

        <SBox data-box="informer">
          <Informer balance={balance} currency={info.currency_hex_code || ''} />
        </SBox>

        <SBox>
          {gameStatus !== GameStatuses.PLAY &&
            countDownTimer > 0 &&
            countDownStartTime > 0 && (
              <>
                <Button
                  disabled
                  mode="count-down"
                  onClick={playGame}
                  startTime={countDownStartTime}
                  time={countDownTimer}
                  onFinished={setCountDownStartTIme}
                />
                <SDownTimerText>{t(Key.nextGameTimerT)}</SDownTimerText>
              </>
            )}

          {(gameStatus === GameStatuses.INIT ||
            GameStatuses.END === gameStatus) &&
            countDownStartTime < 1 && (
              <Button mode="warning" onClick={playGame}>
                <ButtonText>{t(Key.play)}</ButtonText>
              </Button>
            )}

          {GameStatuses.PLAY === gameStatus && (
            <Button
              mode="danger"
              onClick={handleCashout}
              disabled={iterationCount === 0}
            >
              <ButtonText>
                {possibleWin > 0 && iterationCount > 0 ? (
                  <>
                    <span>{t(Key.cashOut)}: </span>
                    <Span hexCode={info.currency_hex_code} />
                    <span>{possibleWin}</span>
                  </>
                ) : (
                  <span>{t(Key.cashOut)}</span>
                )}
              </ButtonText>
            </Button>
          )}

          {freebets?.is_active && (
            <Freebets
              data={freebets}
              currency={<Span hexCode={info.currency_hex_code} />}
            />
          )}

          {controller.amount && !freebets?.is_active && (
            <Controller
              label={t(Key.selectBetAmount)}
              name="amount"
              options={info?.bet_value_list || []}
              value={controller.amount}
              unit={info?.currency_hex_code}
              disabled={GameStatuses.PLAY === gameStatus}
              hasMaxButton={info?.hasMaxBetButton}
              hasMinButton={info?.hasMinBetButton}
              onChange={handleController}
              max={balance}
            />
          )}

          {controller.mines && (
            <Controller
              label={t(Key.mines)}
              name="mines"
              options={info?.mines_value_list || []}
              haveBonus={
                info.bonus_trigger && info?.bonus_trigger[controller.mines]
              }
              showBonus
              value={controller.mines}
              disabled={GameStatuses.PLAY === gameStatus}
              onChange={handleController}
            />
          )}

          <SWinInfo>
            <span>{t(Key.maxWin)} </span>
            <Span hexCode={info?.currency_hex_code} />
            {info &&
              controller.amount &&
              controller.mines &&
              info.max_multipliers && (
                <span>
                  {(
                    info.max_multipliers[controller.mines] * controller.amount
                  ).toFixed(2)}
                </span>
              )}
          </SWinInfo>

          {show_time && <SessionTimer />}

          {info?.hasLobbyButton && (
            <SLobbyLink
              onClick={() => {
                exitLobby(info);
              }}
            >
              {t(Key.backToLobby)}
            </SLobbyLink>
          )}
        </SBox>
      </SSidebar>
      {gameInfo?.reality_check_time > 0 && (
        <PlayingTimer
          historyUrlRef={historyUrlRef}
          time={gameInfo?.reality_check_time}
          isStarted={GameStatuses.PLAY === gameStatus}
          currency={<Span hexCode={info?.currency_hex_code} />}
        />
      )}
    </SWrapper>
  );
};

export default Game;
