import { MutableRefObject } from 'react';

export enum GameStatuses {
  INIT = 'init',
  PLAY = 'play',
  END = 'end',
}

export type TGameStatuses = 'init' | 'play' | 'end';

export interface IGameProps {
  isLoaded?: boolean;
  gameInfo: {
    [key: string]: any;
  };
  historyUrlRef: MutableRefObject<{}>;
}
