import { useState, FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { useInterval } from 'shared/hooks/useInterval';
import Icon from 'shared/components/Icon';

import { SWrapper, SItem, SValue } from './style';

const SessionTimer: FC = () => {
  const [startTime] = useState(dayjs().valueOf());
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());
  const durrantion = dayjs
    .duration(currentTime.valueOf() - startTime)
    .format('HH:mm:ss');

  useInterval(() => {
    setCurrentTime(dayjs());
  }, 1000);

  return (
    <SWrapper>
      {currentTime && (
        <SItem>
          <Icon name="session_time" />
          <SValue>{currentTime.format('HH:mm')}</SValue>
        </SItem>
      )}
      {durrantion && (
        <SItem>
          <Icon name="session_timer" />
          <SValue data-min-width>{durrantion}</SValue>
        </SItem>
      )}
    </SWrapper>
  );
};

export default SessionTimer;
