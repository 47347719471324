import { FC, ReactNode, useState } from 'react';
import { SWrapper, SScrollElement, SHeader, SBody } from './style';

const Collapser: FC<{
  id: number;
  title: ReactNode;
}> = ({ id, title, children }) => {
  const [isActive, setIsActive] = useState(false);

  const toggle = () => {
    setIsActive(p => !p);
  };

  return (
    <SWrapper>
      <SScrollElement data-bet-table={id} />
      <SHeader onClick={toggle} isActive={isActive}>
        {title}
      </SHeader>
      {isActive && <SBody>{children}</SBody>}
    </SWrapper>
  );
};

export default Collapser;
