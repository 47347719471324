import styled, { css } from 'styled-components';
import srcBadgeActive from 'assets/icons/bonus_active.min.png';
import srcBadge from 'assets/icons/bonus_inactive.min.png';

export const SButton = styled.button<{ disabled: boolean }>`
  border: none;
  background-color: transparent;
  background-image: url('${srcBadgeActive}');
  color: var(--inverse-color);
  display: flex;
  height: 40px;
  min-width: 40px;
  padding: 0 5px;
  align-items: center;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  gap: 15px;
  position: absolute;
  bottom: -60px;
  z-index: 20;
  cursor: pointer;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      background-image: url('${srcBadge}');
      cursor: default;
    `};

  @media (max-width: 990px) and (max-aspect-ratio: 1/1) {
    bottom: -50px;
  }

  @media (max-width: 520px) and (max-aspect-ratio: 1/1) {
    bottom: -42px;
  }

  @media (max-width: 450px) and (max-aspect-ratio: 1/1) {
    bottom: -30px;
    height: 30px;
  }

  span {
    display: none;
    max-height: 36px;
  }

  svg {
    width: 36px;
    height: 36px;
  }
`;
